// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/generated/graphql.ts"
);
import.meta.hot.lastModified = "1733322057000";
}
// REMIX HMR END

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Money` scalar type represents monetary values and supports signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
  Money: number;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptBlueCardDetails = {
  __typename?: 'AcceptBlueCardDetails';
  cardType: Scalars['String'];
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  last4: Scalars['String'];
  name: Scalars['String'];
};

export type AcceptBlueCardPaymentMethod = {
  __typename?: 'AcceptBlueCardPaymentMethod';
  avs_address?: Maybe<Scalars['String']>;
  avs_zip?: Maybe<Scalars['String']>;
  card_type?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  expiry_month: Scalars['Int'];
  expiry_year: Scalars['Int'];
  id: Scalars['Int'];
  last4?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payment_method_type?: Maybe<Scalars['String']>;
};

export type AcceptBlueCheckDetails = {
  __typename?: 'AcceptBlueCheckDetails';
  last4: Scalars['String'];
  name: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type AcceptBlueCheckPaymentMethod = {
  __typename?: 'AcceptBlueCheckPaymentMethod';
  account_number?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  customer_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  last4?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payment_method_type?: Maybe<Scalars['String']>;
  routing_number?: Maybe<Scalars['String']>;
  sec_code?: Maybe<Scalars['String']>;
};

export type AcceptBluePaymentMethod = AcceptBlueCardPaymentMethod | AcceptBlueCheckPaymentMethod;

export type AcceptBlueRefundResult = {
  __typename?: 'AcceptBlueRefundResult';
  errorCode?: Maybe<Scalars['String']>;
  errorDetails?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  referenceNumber: Scalars['Int'];
  status: AcceptBlueRefundStatus;
  version: Scalars['String'];
};

export enum AcceptBlueRefundStatus {
  Approved = 'Approved',
  Declined = 'Declined',
  Error = 'Error',
  PartiallyApproved = 'PartiallyApproved'
}

export type AcceptBlueSubscription = {
  __typename?: 'AcceptBlueSubscription';
  amountDueNow: Scalars['Int'];
  /** This ID might not be available yet when an order hasn't been placed yet */
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  priceIncludesTax: Scalars['Boolean'];
  recurring: AcceptBlueSubscriptionRecurringPayment;
  transactions: Array<AcceptBlueTransaction>;
  variantId: Scalars['ID'];
};

export enum AcceptBlueSubscriptionInterval {
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export type AcceptBlueSubscriptionRecurringPayment = {
  __typename?: 'AcceptBlueSubscriptionRecurringPayment';
  amount: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  interval: AcceptBlueSubscriptionInterval;
  intervalCount: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type AcceptBlueTransaction = {
  __typename?: 'AcceptBlueTransaction';
  amount: Scalars['Int'];
  cardDetails?: Maybe<AcceptBlueCardDetails>;
  checkDetails?: Maybe<AcceptBlueCheckDetails>;
  createdAt: Scalars['DateTime'];
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  settledAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
};

export type ActiveOrderResult = NoActiveOrderError | Order;

export type AddCustomerToMyCustomerManagedGroupInput = {
  emailAddress: Scalars['String'];
  isGroupAdmin?: InputMaybe<Scalars['Boolean']>;
};

export type AddPaymentToOrderResult = IneligiblePaymentMethodError | NoActiveOrderError | Order | OrderPaymentStateError | OrderStateTransitionError | PaymentDeclinedError | PaymentFailedError;

export type Address = Node & {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: Country;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  defaultBillingAddress?: Maybe<Scalars['Boolean']>;
  defaultShippingAddress?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  streetLine1: Scalars['String'];
  streetLine2?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type Adjustment = {
  __typename?: 'Adjustment';
  adjustmentSource: Scalars['String'];
  amount: Scalars['Money'];
  data?: Maybe<Scalars['JSON']>;
  description: Scalars['String'];
  type: AdjustmentType;
};

export enum AdjustmentType {
  DistributedOrderPromotion = 'DISTRIBUTED_ORDER_PROMOTION',
  Other = 'OTHER',
  Promotion = 'PROMOTION'
}

export type Agreement = Node & {
  __typename?: 'Agreement';
  agreement: Scalars['String'];
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  label: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AgreementFilterParameter = {
  _and?: InputMaybe<Array<AgreementFilterParameter>>;
  _or?: InputMaybe<Array<AgreementFilterParameter>>;
  agreement?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type AgreementList = PaginatedList & {
  __typename?: 'AgreementList';
  items: Array<Agreement>;
  totalItems: Scalars['Int'];
};

export type AgreementListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<AgreementFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<AgreementSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type AgreementSortParameter = {
  agreement?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Returned when attempting to set the Customer for an Order when already logged in. */
export type AlreadyLoggedInError = ErrorResult & {
  __typename?: 'AlreadyLoggedInError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type ApplyCouponCodeResult = CouponCodeExpiredError | CouponCodeInvalidError | CouponCodeLimitError | Order;

export type Asset = Node & {
  __typename?: 'Asset';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  fileSize: Scalars['Int'];
  focalPoint?: Maybe<Coordinate>;
  height: Scalars['Int'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  preview: Scalars['String'];
  source: Scalars['String'];
  tags: Array<Tag>;
  type: AssetType;
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
};

export type AssetList = PaginatedList & {
  __typename?: 'AssetList';
  items: Array<Asset>;
  totalItems: Scalars['Int'];
};

export enum AssetType {
  Binary = 'BINARY',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type AuthenticationInput = {
  google?: InputMaybe<GoogleAuthInput>;
  google_one_tap?: InputMaybe<GoogleOneTapAuthInput>;
  magic_link_login?: InputMaybe<MagicLinkLoginInput>;
  native?: InputMaybe<NativeAuthInput>;
};

export type AuthenticationMethod = Node & {
  __typename?: 'AuthenticationMethod';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  strategy: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AuthenticationResult = CurrentUser | InvalidCredentialsError | NotVerifiedError;

export type BooleanCustomFieldConfig = CustomField & {
  __typename?: 'BooleanCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

/** Operators for filtering on a list of Boolean fields */
export type BooleanListOperators = {
  inList: Scalars['Boolean'];
};

/** Operators for filtering on a Boolean field */
export type BooleanOperators = {
  eq?: InputMaybe<Scalars['Boolean']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
};

export type Channel = Node & {
  __typename?: 'Channel';
  availableCurrencyCodes: Array<CurrencyCode>;
  availableLanguageCodes?: Maybe<Array<LanguageCode>>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** @deprecated Use defaultCurrencyCode instead */
  currencyCode: CurrencyCode;
  customFields?: Maybe<ChannelCustomFields>;
  defaultCurrencyCode: CurrencyCode;
  defaultLanguageCode: LanguageCode;
  defaultShippingZone?: Maybe<Zone>;
  defaultTaxZone?: Maybe<Zone>;
  id: Scalars['ID'];
  /** Not yet used - will be implemented in a future release. */
  outOfStockThreshold?: Maybe<Scalars['Int']>;
  pricesIncludeTax: Scalars['Boolean'];
  seller?: Maybe<Seller>;
  token: Scalars['String'];
  /** Not yet used - will be implemented in a future release. */
  trackInventory?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
};

export type ChannelCustomFields = {
  __typename?: 'ChannelCustomFields';
  autoApplyPromotions?: Maybe<Scalars['Boolean']>;
  borderRadius?: Maybe<Scalars['String']>;
  daysToAutoremoveEmptyActiveOrders?: Maybe<Scalars['Int']>;
  daysToAutoremoveEmptyDraftOrders?: Maybe<Scalars['Int']>;
  defaultContactEmail?: Maybe<Scalars['String']>;
  defaultFromEmail?: Maybe<Scalars['String']>;
  emailHeader?: Maybe<Asset>;
  enableEmailLoginLink?: Maybe<Scalars['Boolean']>;
  enforceChargeDayOnAdmin?: Maybe<Scalars['Boolean']>;
  errorColor?: Maybe<Scalars['String']>;
  errorTextColor?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
  favIcon?: Maybe<Asset>;
  googleClientId?: Maybe<Scalars['String']>;
  gotifyApplicationId?: Maybe<Scalars['Int']>;
  gotifyApplicationToken?: Maybe<Scalars['String']>;
  gotifyClientToken?: Maybe<Scalars['String']>;
  highlightColor?: Maybe<Scalars['String']>;
  highlightTextColor?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  primaryTextColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  secondaryTextColor?: Maybe<Scalars['String']>;
  selectableChargeDayOfMonth?: Maybe<Array<Scalars['Int']>>;
  showSkuForVariants?: Maybe<Scalars['Boolean']>;
  showVariantDescriptionByDefault?: Maybe<Scalars['Boolean']>;
  smtpDebug?: Maybe<Scalars['Boolean']>;
  smtpEnabled?: Maybe<Scalars['Boolean']>;
  smtpHost?: Maybe<Scalars['String']>;
  smtpName?: Maybe<Scalars['String']>;
  smtpPassword?: Maybe<Scalars['String']>;
  smtpPort?: Maybe<Scalars['Int']>;
  smtpSecure?: Maybe<Scalars['Boolean']>;
  smtpUser?: Maybe<Scalars['String']>;
  storeDescription?: Maybe<Scalars['String']>;
  storeHero?: Maybe<Asset>;
  storeIcon?: Maybe<Asset>;
  storeLogo?: Maybe<Asset>;
  storePhone?: Maybe<Scalars['String']>;
  storeSlogan?: Maybe<Scalars['String']>;
  storeTitle?: Maybe<Scalars['String']>;
  storeURL?: Maybe<Scalars['String']>;
  successColor?: Maybe<Scalars['String']>;
  successTextColor?: Maybe<Scalars['String']>;
  tiktokUrl?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  warnColor?: Maybe<Scalars['String']>;
  warnTextColor?: Maybe<Scalars['String']>;
  xUrl?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};

export type Collection = Node & {
  __typename?: 'Collection';
  assets: Array<Asset>;
  breadcrumbs: Array<CollectionBreadcrumb>;
  children?: Maybe<Array<Collection>>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<CollectionCustomFields>;
  description: Scalars['String'];
  featuredAsset?: Maybe<Asset>;
  filters: Array<ConfigurableOperation>;
  id: Scalars['ID'];
  languageCode?: Maybe<LanguageCode>;
  name: Scalars['String'];
  parent?: Maybe<Collection>;
  parentId: Scalars['ID'];
  position: Scalars['Int'];
  productVariants: ProductVariantList;
  slug: Scalars['String'];
  translations: Array<CollectionTranslation>;
  updatedAt: Scalars['DateTime'];
};


export type CollectionProductVariantsArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
};

export type CollectionBreadcrumb = {
  __typename?: 'CollectionBreadcrumb';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type CollectionCustomFields = {
  __typename?: 'CollectionCustomFields';
  agreement?: Maybe<Agreement>;
  includeInMetrics?: Maybe<Scalars['Boolean']>;
  pricingInterval?: Maybe<Scalars['String']>;
  pricingLowest?: Maybe<Scalars['Int']>;
  pricingUseCalculated?: Maybe<Scalars['Boolean']>;
};

export type CollectionFilterParameter = {
  _and?: InputMaybe<Array<CollectionFilterParameter>>;
  _or?: InputMaybe<Array<CollectionFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  includeInMetrics?: InputMaybe<BooleanOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  parentId?: InputMaybe<IdOperators>;
  position?: InputMaybe<NumberOperators>;
  pricingInterval?: InputMaybe<StringOperators>;
  pricingLowest?: InputMaybe<NumberOperators>;
  pricingUseCalculated?: InputMaybe<BooleanOperators>;
  slug?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CollectionList = PaginatedList & {
  __typename?: 'CollectionList';
  items: Array<Collection>;
  totalItems: Scalars['Int'];
};

export type CollectionListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CollectionFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CollectionSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
  topLevelOnly?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Which Collections are present in the products returned
 * by the search, and in what quantity.
 */
export type CollectionResult = {
  __typename?: 'CollectionResult';
  collection: Collection;
  count: Scalars['Int'];
};

export type CollectionSortParameter = {
  agreement?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  includeInMetrics?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  pricingInterval?: InputMaybe<SortOrder>;
  pricingLowest?: InputMaybe<SortOrder>;
  pricingUseCalculated?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CollectionTranslation = {
  __typename?: 'CollectionTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ConfigArg = {
  __typename?: 'ConfigArg';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigArgDefinition = {
  __typename?: 'ConfigArgDefinition';
  defaultValue?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  required: Scalars['Boolean'];
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type ConfigArgInput = {
  name: Scalars['String'];
  /** A JSON stringified representation of the actual value */
  value: Scalars['String'];
};

export type ConfigurableOperation = {
  __typename?: 'ConfigurableOperation';
  args: Array<ConfigArg>;
  code: Scalars['String'];
};

export type ConfigurableOperationDefinition = {
  __typename?: 'ConfigurableOperationDefinition';
  args: Array<ConfigArgDefinition>;
  code: Scalars['String'];
  description: Scalars['String'];
};

export type ConfigurableOperationInput = {
  arguments: Array<ConfigArgInput>;
  code: Scalars['String'];
};

export type Coordinate = {
  __typename?: 'Coordinate';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

/**
 * A Country of the world which your shop operates in.
 *
 * The `code` field is typically a 2-character ISO code such as "GB", "US", "DE" etc. This code is used in certain inputs such as
 * `UpdateAddressInput` and `CreateAddressInput` to specify the country.
 */
export type Country = Node & Region & {
  __typename?: 'Country';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  parent?: Maybe<Region>;
  parentId?: Maybe<Scalars['ID']>;
  translations: Array<RegionTranslation>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CountryList = PaginatedList & {
  __typename?: 'CountryList';
  items: Array<Country>;
  totalItems: Scalars['Int'];
};

/** Returned if the provided coupon code is invalid */
export type CouponCodeExpiredError = ErrorResult & {
  __typename?: 'CouponCodeExpiredError';
  couponCode: Scalars['String'];
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned if the provided coupon code is invalid */
export type CouponCodeInvalidError = ErrorResult & {
  __typename?: 'CouponCodeInvalidError';
  couponCode: Scalars['String'];
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned if the provided coupon code is invalid */
export type CouponCodeLimitError = ErrorResult & {
  __typename?: 'CouponCodeLimitError';
  couponCode: Scalars['String'];
  errorCode: ErrorCode;
  limit: Scalars['Int'];
  message: Scalars['String'];
};

/**
 * Input used to create an Address.
 *
 * The countryCode must correspond to a `code` property of a Country that has been defined in the
 * Vendure server. The `code` property is typically a 2-character ISO code such as "GB", "US", "DE" etc.
 * If an invalid code is passed, the mutation will fail.
 */
export type CreateAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  customFields?: InputMaybe<Scalars['JSON']>;
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']>;
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  streetLine1: Scalars['String'];
  streetLine2?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerCustomFieldsInput = {
  acceptBlueCustomerId?: InputMaybe<Scalars['Int']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  customerRankId?: InputMaybe<Scalars['ID']>;
  guardianId?: InputMaybe<Scalars['ID']>;
  healthProfileResult?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['ID']>;
  phoneticName?: InputMaybe<Scalars['String']>;
  trialCompleted?: InputMaybe<Scalars['DateTime']>;
};

export type CreateCustomerInput = {
  customFields?: InputMaybe<CreateCustomerCustomFieldsInput>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/**
 * @description
 * ISO 4217 currency code
 *
 * @docsCategory common
 */
export enum CurrencyCode {
  /** United Arab Emirates dirham */
  Aed = 'AED',
  /** Afghan afghani */
  Afn = 'AFN',
  /** Albanian lek */
  All = 'ALL',
  /** Armenian dram */
  Amd = 'AMD',
  /** Netherlands Antillean guilder */
  Ang = 'ANG',
  /** Angolan kwanza */
  Aoa = 'AOA',
  /** Argentine peso */
  Ars = 'ARS',
  /** Australian dollar */
  Aud = 'AUD',
  /** Aruban florin */
  Awg = 'AWG',
  /** Azerbaijani manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina convertible mark */
  Bam = 'BAM',
  /** Barbados dollar */
  Bbd = 'BBD',
  /** Bangladeshi taka */
  Bdt = 'BDT',
  /** Bulgarian lev */
  Bgn = 'BGN',
  /** Bahraini dinar */
  Bhd = 'BHD',
  /** Burundian franc */
  Bif = 'BIF',
  /** Bermudian dollar */
  Bmd = 'BMD',
  /** Brunei dollar */
  Bnd = 'BND',
  /** Boliviano */
  Bob = 'BOB',
  /** Brazilian real */
  Brl = 'BRL',
  /** Bahamian dollar */
  Bsd = 'BSD',
  /** Bhutanese ngultrum */
  Btn = 'BTN',
  /** Botswana pula */
  Bwp = 'BWP',
  /** Belarusian ruble */
  Byn = 'BYN',
  /** Belize dollar */
  Bzd = 'BZD',
  /** Canadian dollar */
  Cad = 'CAD',
  /** Congolese franc */
  Cdf = 'CDF',
  /** Swiss franc */
  Chf = 'CHF',
  /** Chilean peso */
  Clp = 'CLP',
  /** Renminbi (Chinese) yuan */
  Cny = 'CNY',
  /** Colombian peso */
  Cop = 'COP',
  /** Costa Rican colon */
  Crc = 'CRC',
  /** Cuban convertible peso */
  Cuc = 'CUC',
  /** Cuban peso */
  Cup = 'CUP',
  /** Cape Verde escudo */
  Cve = 'CVE',
  /** Czech koruna */
  Czk = 'CZK',
  /** Djiboutian franc */
  Djf = 'DJF',
  /** Danish krone */
  Dkk = 'DKK',
  /** Dominican peso */
  Dop = 'DOP',
  /** Algerian dinar */
  Dzd = 'DZD',
  /** Egyptian pound */
  Egp = 'EGP',
  /** Eritrean nakfa */
  Ern = 'ERN',
  /** Ethiopian birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fiji dollar */
  Fjd = 'FJD',
  /** Falkland Islands pound */
  Fkp = 'FKP',
  /** Pound sterling */
  Gbp = 'GBP',
  /** Georgian lari */
  Gel = 'GEL',
  /** Ghanaian cedi */
  Ghs = 'GHS',
  /** Gibraltar pound */
  Gip = 'GIP',
  /** Gambian dalasi */
  Gmd = 'GMD',
  /** Guinean franc */
  Gnf = 'GNF',
  /** Guatemalan quetzal */
  Gtq = 'GTQ',
  /** Guyanese dollar */
  Gyd = 'GYD',
  /** Hong Kong dollar */
  Hkd = 'HKD',
  /** Honduran lempira */
  Hnl = 'HNL',
  /** Croatian kuna */
  Hrk = 'HRK',
  /** Haitian gourde */
  Htg = 'HTG',
  /** Hungarian forint */
  Huf = 'HUF',
  /** Indonesian rupiah */
  Idr = 'IDR',
  /** Israeli new shekel */
  Ils = 'ILS',
  /** Indian rupee */
  Inr = 'INR',
  /** Iraqi dinar */
  Iqd = 'IQD',
  /** Iranian rial */
  Irr = 'IRR',
  /** Icelandic króna */
  Isk = 'ISK',
  /** Jamaican dollar */
  Jmd = 'JMD',
  /** Jordanian dinar */
  Jod = 'JOD',
  /** Japanese yen */
  Jpy = 'JPY',
  /** Kenyan shilling */
  Kes = 'KES',
  /** Kyrgyzstani som */
  Kgs = 'KGS',
  /** Cambodian riel */
  Khr = 'KHR',
  /** Comoro franc */
  Kmf = 'KMF',
  /** North Korean won */
  Kpw = 'KPW',
  /** South Korean won */
  Krw = 'KRW',
  /** Kuwaiti dinar */
  Kwd = 'KWD',
  /** Cayman Islands dollar */
  Kyd = 'KYD',
  /** Kazakhstani tenge */
  Kzt = 'KZT',
  /** Lao kip */
  Lak = 'LAK',
  /** Lebanese pound */
  Lbp = 'LBP',
  /** Sri Lankan rupee */
  Lkr = 'LKR',
  /** Liberian dollar */
  Lrd = 'LRD',
  /** Lesotho loti */
  Lsl = 'LSL',
  /** Libyan dinar */
  Lyd = 'LYD',
  /** Moroccan dirham */
  Mad = 'MAD',
  /** Moldovan leu */
  Mdl = 'MDL',
  /** Malagasy ariary */
  Mga = 'MGA',
  /** Macedonian denar */
  Mkd = 'MKD',
  /** Myanmar kyat */
  Mmk = 'MMK',
  /** Mongolian tögrög */
  Mnt = 'MNT',
  /** Macanese pataca */
  Mop = 'MOP',
  /** Mauritanian ouguiya */
  Mru = 'MRU',
  /** Mauritian rupee */
  Mur = 'MUR',
  /** Maldivian rufiyaa */
  Mvr = 'MVR',
  /** Malawian kwacha */
  Mwk = 'MWK',
  /** Mexican peso */
  Mxn = 'MXN',
  /** Malaysian ringgit */
  Myr = 'MYR',
  /** Mozambican metical */
  Mzn = 'MZN',
  /** Namibian dollar */
  Nad = 'NAD',
  /** Nigerian naira */
  Ngn = 'NGN',
  /** Nicaraguan córdoba */
  Nio = 'NIO',
  /** Norwegian krone */
  Nok = 'NOK',
  /** Nepalese rupee */
  Npr = 'NPR',
  /** New Zealand dollar */
  Nzd = 'NZD',
  /** Omani rial */
  Omr = 'OMR',
  /** Panamanian balboa */
  Pab = 'PAB',
  /** Peruvian sol */
  Pen = 'PEN',
  /** Papua New Guinean kina */
  Pgk = 'PGK',
  /** Philippine peso */
  Php = 'PHP',
  /** Pakistani rupee */
  Pkr = 'PKR',
  /** Polish złoty */
  Pln = 'PLN',
  /** Paraguayan guaraní */
  Pyg = 'PYG',
  /** Qatari riyal */
  Qar = 'QAR',
  /** Romanian leu */
  Ron = 'RON',
  /** Serbian dinar */
  Rsd = 'RSD',
  /** Russian ruble */
  Rub = 'RUB',
  /** Rwandan franc */
  Rwf = 'RWF',
  /** Saudi riyal */
  Sar = 'SAR',
  /** Solomon Islands dollar */
  Sbd = 'SBD',
  /** Seychelles rupee */
  Scr = 'SCR',
  /** Sudanese pound */
  Sdg = 'SDG',
  /** Swedish krona/kronor */
  Sek = 'SEK',
  /** Singapore dollar */
  Sgd = 'SGD',
  /** Saint Helena pound */
  Shp = 'SHP',
  /** Sierra Leonean leone */
  Sll = 'SLL',
  /** Somali shilling */
  Sos = 'SOS',
  /** Surinamese dollar */
  Srd = 'SRD',
  /** South Sudanese pound */
  Ssp = 'SSP',
  /** São Tomé and Príncipe dobra */
  Stn = 'STN',
  /** Salvadoran colón */
  Svc = 'SVC',
  /** Syrian pound */
  Syp = 'SYP',
  /** Swazi lilangeni */
  Szl = 'SZL',
  /** Thai baht */
  Thb = 'THB',
  /** Tajikistani somoni */
  Tjs = 'TJS',
  /** Turkmenistan manat */
  Tmt = 'TMT',
  /** Tunisian dinar */
  Tnd = 'TND',
  /** Tongan paʻanga */
  Top = 'TOP',
  /** Turkish lira */
  Try = 'TRY',
  /** Trinidad and Tobago dollar */
  Ttd = 'TTD',
  /** New Taiwan dollar */
  Twd = 'TWD',
  /** Tanzanian shilling */
  Tzs = 'TZS',
  /** Ukrainian hryvnia */
  Uah = 'UAH',
  /** Ugandan shilling */
  Ugx = 'UGX',
  /** United States dollar */
  Usd = 'USD',
  /** Uruguayan peso */
  Uyu = 'UYU',
  /** Uzbekistan som */
  Uzs = 'UZS',
  /** Venezuelan bolívar soberano */
  Ves = 'VES',
  /** Vietnamese đồng */
  Vnd = 'VND',
  /** Vanuatu vatu */
  Vuv = 'VUV',
  /** Samoan tala */
  Wst = 'WST',
  /** CFA franc BEAC */
  Xaf = 'XAF',
  /** East Caribbean dollar */
  Xcd = 'XCD',
  /** CFA franc BCEAO */
  Xof = 'XOF',
  /** CFP franc (franc Pacifique) */
  Xpf = 'XPF',
  /** Yemeni rial */
  Yer = 'YER',
  /** South African rand */
  Zar = 'ZAR',
  /** Zambian kwacha */
  Zmw = 'ZMW',
  /** Zimbabwean dollar */
  Zwl = 'ZWL'
}

export type CurrentUser = {
  __typename?: 'CurrentUser';
  channels: Array<CurrentUserChannel>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
};

export type CurrentUserChannel = {
  __typename?: 'CurrentUserChannel';
  code: Scalars['String'];
  id: Scalars['ID'];
  permissions: Array<Permission>;
  token: Scalars['String'];
};

export type CustomField = {
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type CustomFieldConfig = BooleanCustomFieldConfig | DateTimeCustomFieldConfig | FloatCustomFieldConfig | IntCustomFieldConfig | LocaleStringCustomFieldConfig | LocaleTextCustomFieldConfig | RelationCustomFieldConfig | StringCustomFieldConfig | TextCustomFieldConfig;

export type Customer = Node & {
  __typename?: 'Customer';
  addresses?: Maybe<Array<Address>>;
  age?: Maybe<Scalars['Int']>;
  classGroups?: Maybe<Array<RsvClassGroup>>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<CustomerCustomFields>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  healthProfile?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  orders: OrderList;
  phoneNumber?: Maybe<Scalars['String']>;
  savedAcceptBluePaymentMethods: Array<AcceptBluePaymentMethod>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};


export type CustomerOrdersArgs = {
  options?: InputMaybe<OrderListOptions>;
};

export type CustomerCustomFields = {
  __typename?: 'CustomerCustomFields';
  acceptBlueCustomerId?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['DateTime']>;
  customerRank?: Maybe<RsvCustomerRank>;
  guardian?: Maybe<Customer>;
  healthProfileResult?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  phoneticName?: Maybe<Scalars['String']>;
  trialCompleted?: Maybe<Scalars['DateTime']>;
};

export type CustomerFilterParameter = {
  _and?: InputMaybe<Array<CustomerFilterParameter>>;
  _or?: InputMaybe<Array<CustomerFilterParameter>>;
  acceptBlueCustomerId?: InputMaybe<NumberOperators>;
  age?: InputMaybe<NumberOperators>;
  birthday?: InputMaybe<DateOperators>;
  createdAt?: InputMaybe<DateOperators>;
  emailAddress?: InputMaybe<StringOperators>;
  firstName?: InputMaybe<StringOperators>;
  healthProfile?: InputMaybe<StringOperators>;
  healthProfileResult?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  lastName?: InputMaybe<StringOperators>;
  phoneNumber?: InputMaybe<StringOperators>;
  phoneticName?: InputMaybe<StringOperators>;
  title?: InputMaybe<StringOperators>;
  trialCompleted?: InputMaybe<DateOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type CustomerGroup = Node & {
  __typename?: 'CustomerGroup';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<CustomerGroupCustomFields>;
  customers: CustomerList;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type CustomerGroupCustomersArgs = {
  options?: InputMaybe<CustomerListOptions>;
};

export type CustomerGroupCustomFields = {
  __typename?: 'CustomerGroupCustomFields';
  groupAdmins?: Maybe<Array<Customer>>;
};

export type CustomerList = PaginatedList & {
  __typename?: 'CustomerList';
  items: Array<Customer>;
  totalItems: Scalars['Int'];
};

export type CustomerListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<CustomerFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<CustomerSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type CustomerManagedGroup = {
  __typename?: 'CustomerManagedGroup';
  createdAt: Scalars['DateTime'];
  customers: Array<CustomerManagedGroupMember>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CustomerManagedGroupAddress = {
  __typename?: 'CustomerManagedGroupAddress';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: CustomerManagedGroupCountry;
  createdAt: Scalars['DateTime'];
  defaultBillingAddress?: Maybe<Scalars['Boolean']>;
  defaultShippingAddress?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  streetLine1: Scalars['String'];
  streetLine2?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** When no ID is given, a new address will be created */
export type CustomerManagedGroupAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']>;
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  streetLine1?: InputMaybe<Scalars['String']>;
  streetLine2?: InputMaybe<Scalars['String']>;
};

export type CustomerManagedGroupCountry = {
  __typename?: 'CustomerManagedGroupCountry';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  translations: Array<CustomerManagedGroupCountryTranslation>;
  updatedAt: Scalars['DateTime'];
};

export type CustomerManagedGroupCountryTranslation = {
  __typename?: 'CustomerManagedGroupCountryTranslation';
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
};

export type CustomerManagedGroupMember = {
  __typename?: 'CustomerManagedGroupMember';
  addresses?: Maybe<Array<CustomerManagedGroupAddress>>;
  customFields?: Maybe<Scalars['JSON']>;
  customer?: Maybe<Customer>;
  customerId: Scalars['ID'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  isGroupAdministrator: Scalars['Boolean'];
  lastName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type CustomerSortParameter = {
  acceptBlueCustomerId?: InputMaybe<SortOrder>;
  age?: InputMaybe<SortOrder>;
  birthday?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerRank?: InputMaybe<SortOrder>;
  emailAddress?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  guardian?: InputMaybe<SortOrder>;
  healthProfile?: InputMaybe<SortOrder>;
  healthProfileResult?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  phoneticName?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  trialCompleted?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Operators for filtering on a list of Date fields */
export type DateListOperators = {
  inList: Scalars['DateTime'];
};

/** Operators for filtering on a DateTime field */
export type DateOperators = {
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
  between?: InputMaybe<DateRange>;
  eq?: InputMaybe<Scalars['DateTime']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
};

export type DateRange = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

/**
 * Expects the same validation formats as the `<input type="datetime-local">` HTML element.
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#Additional_attributes
 */
export type DateTimeCustomFieldConfig = CustomField & {
  __typename?: 'DateTimeCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  max?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  step?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type DefaultGlobalSettings = {
  __typename?: 'DefaultGlobalSettings';
  gotifyWebsocketUrl?: Maybe<Scalars['String']>;
};

export type DefaultSettings = {
  __typename?: 'DefaultSettings';
  frontendSettings: DefaultSiteSettings;
  globalSettings: DefaultGlobalSettings;
};

export type DefaultSiteSettings = {
  __typename?: 'DefaultSiteSettings';
  errorColor: Scalars['String'];
  highlightColor: Scalars['String'];
  primaryColor: Scalars['String'];
  secondaryColor: Scalars['String'];
  warnColor: Scalars['String'];
};

export type DeletionResponse = {
  __typename?: 'DeletionResponse';
  message?: Maybe<Scalars['String']>;
  result: DeletionResult;
};

export enum DeletionResult {
  /** The entity was successfully deleted */
  Deleted = 'DELETED',
  /** Deletion did not take place, reason given in message */
  NotDeleted = 'NOT_DELETED'
}

export type Discount = {
  __typename?: 'Discount';
  adjustmentSource: Scalars['String'];
  amount: Scalars['Money'];
  amountWithTax: Scalars['Money'];
  description: Scalars['String'];
  type: AdjustmentType;
};

/** Returned when attempting to create a Customer with an email address already registered to an existing User. */
export type EmailAddressConflictError = ErrorResult & {
  __typename?: 'EmailAddressConflictError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export enum ErrorCode {
  AlreadyLoggedInError = 'ALREADY_LOGGED_IN_ERROR',
  CouponCodeExpiredError = 'COUPON_CODE_EXPIRED_ERROR',
  CouponCodeInvalidError = 'COUPON_CODE_INVALID_ERROR',
  CouponCodeLimitError = 'COUPON_CODE_LIMIT_ERROR',
  EmailAddressConflictError = 'EMAIL_ADDRESS_CONFLICT_ERROR',
  FailedPickupCodeError = 'FAILED_PICKUP_CODE_ERROR',
  GoogleAuthStrategyError = 'GOOGLE_AUTH_STRATEGY_ERROR',
  GuestCheckoutError = 'GUEST_CHECKOUT_ERROR',
  IdentifierChangeTokenExpiredError = 'IDENTIFIER_CHANGE_TOKEN_EXPIRED_ERROR',
  IdentifierChangeTokenInvalidError = 'IDENTIFIER_CHANGE_TOKEN_INVALID_ERROR',
  IneligiblePaymentMethodError = 'INELIGIBLE_PAYMENT_METHOD_ERROR',
  IneligibleShippingMethodError = 'INELIGIBLE_SHIPPING_METHOD_ERROR',
  InsufficientStockError = 'INSUFFICIENT_STOCK_ERROR',
  InvalidCredentialsError = 'INVALID_CREDENTIALS_ERROR',
  MembershipTransitionError = 'MEMBERSHIP_TRANSITION_ERROR',
  MissingLoginStrategyError = 'MISSING_LOGIN_STRATEGY_ERROR',
  MissingPasswordError = 'MISSING_PASSWORD_ERROR',
  NativeAuthStrategyError = 'NATIVE_AUTH_STRATEGY_ERROR',
  NegativeQuantityError = 'NEGATIVE_QUANTITY_ERROR',
  NotVerifiedError = 'NOT_VERIFIED_ERROR',
  NoActiveOrderError = 'NO_ACTIVE_ORDER_ERROR',
  OrderLimitError = 'ORDER_LIMIT_ERROR',
  OrderModificationError = 'ORDER_MODIFICATION_ERROR',
  OrderPaymentStateError = 'ORDER_PAYMENT_STATE_ERROR',
  OrderStateTransitionError = 'ORDER_STATE_TRANSITION_ERROR',
  PasswordAlreadySetError = 'PASSWORD_ALREADY_SET_ERROR',
  PasswordResetTokenExpiredError = 'PASSWORD_RESET_TOKEN_EXPIRED_ERROR',
  PasswordResetTokenInvalidError = 'PASSWORD_RESET_TOKEN_INVALID_ERROR',
  PasswordValidationError = 'PASSWORD_VALIDATION_ERROR',
  PaymentDeclinedError = 'PAYMENT_DECLINED_ERROR',
  PaymentFailedError = 'PAYMENT_FAILED_ERROR',
  UnknownError = 'UNKNOWN_ERROR',
  ValidationError = 'VALIDATION_ERROR',
  VerificationTokenExpiredError = 'VERIFICATION_TOKEN_EXPIRED_ERROR',
  VerificationTokenInvalidError = 'VERIFICATION_TOKEN_INVALID_ERROR'
}

export type ErrorResult = {
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type Facet = Node & {
  __typename?: 'Facet';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  translations: Array<FacetTranslation>;
  updatedAt: Scalars['DateTime'];
  /** Returns a paginated, sortable, filterable list of the Facet's values. Added in v2.1.0. */
  valueList: FacetValueList;
  values: Array<FacetValue>;
};


export type FacetValueListArgs = {
  options?: InputMaybe<FacetValueListOptions>;
};

export type FacetFilterParameter = {
  _and?: InputMaybe<Array<FacetFilterParameter>>;
  _or?: InputMaybe<Array<FacetFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type FacetList = PaginatedList & {
  __typename?: 'FacetList';
  items: Array<Facet>;
  totalItems: Scalars['Int'];
};

export type FacetListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<FacetFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<FacetSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type FacetSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FacetTranslation = {
  __typename?: 'FacetTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FacetValue = Node & {
  __typename?: 'FacetValue';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  facet: Facet;
  facetId: Scalars['ID'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  translations: Array<FacetValueTranslation>;
  updatedAt: Scalars['DateTime'];
};

/**
 * Used to construct boolean expressions for filtering search results
 * by FacetValue ID. Examples:
 *
 * * ID=1 OR ID=2: `{ facetValueFilters: [{ or: [1,2] }] }`
 * * ID=1 AND ID=2: `{ facetValueFilters: [{ and: 1 }, { and: 2 }] }`
 * * ID=1 AND (ID=2 OR ID=3): `{ facetValueFilters: [{ and: 1 }, { or: [2,3] }] }`
 */
export type FacetValueFilterInput = {
  and?: InputMaybe<Scalars['ID']>;
  or?: InputMaybe<Array<Scalars['ID']>>;
};

export type FacetValueFilterParameter = {
  _and?: InputMaybe<Array<FacetValueFilterParameter>>;
  _or?: InputMaybe<Array<FacetValueFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  facetId?: InputMaybe<IdOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type FacetValueList = PaginatedList & {
  __typename?: 'FacetValueList';
  items: Array<FacetValue>;
  totalItems: Scalars['Int'];
};

export type FacetValueListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<FacetValueFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<FacetValueSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

/**
 * Which FacetValues are present in the products returned
 * by the search, and in what quantity.
 */
export type FacetValueResult = {
  __typename?: 'FacetValueResult';
  count: Scalars['Int'];
  facetValue: FacetValue;
};

export type FacetValueSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  facetId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FacetValueTranslation = {
  __typename?: 'FacetValueTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FailedPickupCodeError = ErrorResult & {
  __typename?: 'FailedPickupCodeError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type FilteredCustomerManagedGroupMember = {
  __typename?: 'FilteredCustomerManagedGroupMember';
  customer: Customer;
  isGroupAdministrator: Scalars['Boolean'];
  unavailabilityCode?: Maybe<UnavailabilityCode>;
  unavailabilityReason?: Maybe<Scalars['String']>;
};

export type FloatCustomFieldConfig = CustomField & {
  __typename?: 'FloatCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  step?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type Fulfillment = Node & {
  __typename?: 'Fulfillment';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  lines: Array<FulfillmentLine>;
  method: Scalars['String'];
  state: Scalars['String'];
  /** @deprecated Use the `lines` field instead */
  summary: Array<FulfillmentLine>;
  trackingCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type FulfillmentLine = {
  __typename?: 'FulfillmentLine';
  fulfillment: Fulfillment;
  fulfillmentId: Scalars['ID'];
  orderLine: OrderLine;
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export enum GlobalFlag {
  False = 'FALSE',
  Inherit = 'INHERIT',
  True = 'TRUE'
}

export type GoogleAuthInput = {
  code: Scalars['String'];
  codeVerifier?: InputMaybe<Scalars['String']>;
};

export type GoogleAuthStrategyError = ErrorResult & {
  __typename?: 'GoogleAuthStrategyError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type GoogleOneTapAuthInput = {
  credential: Scalars['String'];
};

/** Returned when attempting to set the Customer on a guest checkout when the configured GuestCheckoutStrategy does not allow it. */
export type GuestCheckoutError = ErrorResult & {
  __typename?: 'GuestCheckoutError';
  errorCode: ErrorCode;
  errorDetail: Scalars['String'];
  message: Scalars['String'];
};

export type HistoryEntry = Node & {
  __typename?: 'HistoryEntry';
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  id: Scalars['ID'];
  type: HistoryEntryType;
  updatedAt: Scalars['DateTime'];
};

export type HistoryEntryFilterParameter = {
  _and?: InputMaybe<Array<HistoryEntryFilterParameter>>;
  _or?: InputMaybe<Array<HistoryEntryFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type HistoryEntryList = PaginatedList & {
  __typename?: 'HistoryEntryList';
  items: Array<HistoryEntry>;
  totalItems: Scalars['Int'];
};

export type HistoryEntryListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<HistoryEntryFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<HistoryEntrySortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type HistoryEntrySortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum HistoryEntryType {
  AgreementSigned = 'AGREEMENT_SIGNED',
  ChildPickedUp = 'CHILD_PICKED_UP',
  CustomerAddedToGroup = 'CUSTOMER_ADDED_TO_GROUP',
  CustomerAddressCreated = 'CUSTOMER_ADDRESS_CREATED',
  CustomerAddressDeleted = 'CUSTOMER_ADDRESS_DELETED',
  CustomerAddressUpdated = 'CUSTOMER_ADDRESS_UPDATED',
  CustomerDetailUpdated = 'CUSTOMER_DETAIL_UPDATED',
  CustomerEmailUpdateRequested = 'CUSTOMER_EMAIL_UPDATE_REQUESTED',
  CustomerEmailUpdateVerified = 'CUSTOMER_EMAIL_UPDATE_VERIFIED',
  CustomerNote = 'CUSTOMER_NOTE',
  CustomerPasswordResetRequested = 'CUSTOMER_PASSWORD_RESET_REQUESTED',
  CustomerPasswordResetVerified = 'CUSTOMER_PASSWORD_RESET_VERIFIED',
  CustomerPasswordUpdated = 'CUSTOMER_PASSWORD_UPDATED',
  CustomerRegistered = 'CUSTOMER_REGISTERED',
  CustomerRemovedFromGroup = 'CUSTOMER_REMOVED_FROM_GROUP',
  CustomerVerified = 'CUSTOMER_VERIFIED',
  MembershipStateTransition = 'MEMBERSHIP_STATE_TRANSITION',
  OrderCancellation = 'ORDER_CANCELLATION',
  OrderCouponApplied = 'ORDER_COUPON_APPLIED',
  OrderCouponRemoved = 'ORDER_COUPON_REMOVED',
  OrderCustomerUpdated = 'ORDER_CUSTOMER_UPDATED',
  OrderFulfillment = 'ORDER_FULFILLMENT',
  OrderFulfillmentTransition = 'ORDER_FULFILLMENT_TRANSITION',
  OrderModified = 'ORDER_MODIFIED',
  OrderNote = 'ORDER_NOTE',
  OrderPaymentTransition = 'ORDER_PAYMENT_TRANSITION',
  OrderRefundTransition = 'ORDER_REFUND_TRANSITION',
  OrderStateTransition = 'ORDER_STATE_TRANSITION',
  ParticipantCheckin = 'PARTICIPANT_CHECKIN',
  ParticipantMessage = 'PARTICIPANT_MESSAGE',
  ParticipantNote = 'PARTICIPANT_NOTE',
  ParticipantOnboard = 'PARTICIPANT_ONBOARD',
  ParticipantStateTransition = 'PARTICIPANT_STATE_TRANSITION',
  ParticipantTransfer = 'PARTICIPANT_TRANSFER',
  PickupcodeGenerated = 'PICKUPCODE_GENERATED',
  StaffNote = 'STAFF_NOTE',
  SubscriptionChange = 'SUBSCRIPTION_CHANGE',
  SubscriptionNotification = 'SUBSCRIPTION_NOTIFICATION',
  SubscriptionPaymentTransition = 'SUBSCRIPTION_PAYMENT_TRANSITION',
  SubscriptionRefundTransition = 'SUBSCRIPTION_REFUND_TRANSITION'
}

/** Operators for filtering on a list of ID fields */
export type IdListOperators = {
  inList: Scalars['ID'];
};

/** Operators for filtering on an ID field */
export type IdOperators = {
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  notEq?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
};

/**
 * Returned if the token used to change a Customer's email address is valid, but has
 * expired according to the `verificationTokenDuration` setting in the AuthOptions.
 */
export type IdentifierChangeTokenExpiredError = ErrorResult & {
  __typename?: 'IdentifierChangeTokenExpiredError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/**
 * Returned if the token used to change a Customer's email address is either
 * invalid or does not match any expected tokens.
 */
export type IdentifierChangeTokenInvalidError = ErrorResult & {
  __typename?: 'IdentifierChangeTokenInvalidError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when attempting to add a Payment using a PaymentMethod for which the Order is not eligible. */
export type IneligiblePaymentMethodError = ErrorResult & {
  __typename?: 'IneligiblePaymentMethodError';
  eligibilityCheckerMessage?: Maybe<Scalars['String']>;
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when attempting to set a ShippingMethod for which the Order is not eligible */
export type IneligibleShippingMethodError = ErrorResult & {
  __typename?: 'IneligibleShippingMethodError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when attempting to add more items to the Order than are available */
export type InsufficientStockError = ErrorResult & {
  __typename?: 'InsufficientStockError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  order: Order;
  quantityAvailable: Scalars['Int'];
};

export type IntCustomFieldConfig = CustomField & {
  __typename?: 'IntCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  step?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

/** Returned if the user authentication credentials are not valid */
export type InvalidCredentialsError = ErrorResult & {
  __typename?: 'InvalidCredentialsError';
  authenticationError: Scalars['String'];
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/**
 * @description
 * Languages in the form of a ISO 639-1 language code with optional
 * region or script modifier (e.g. de_AT). The selection available is based
 * on the [Unicode CLDR summary list](https://unicode-org.github.io/cldr-staging/charts/37/summary/root.html)
 * and includes the major spoken languages of the world and any widely-used variants.
 *
 * @docsCategory common
 */
export enum LanguageCode {
  /** Afrikaans */
  Af = 'af',
  /** Akan */
  Ak = 'ak',
  /** Amharic */
  Am = 'am',
  /** Arabic */
  Ar = 'ar',
  /** Assamese */
  As = 'as',
  /** Azerbaijani */
  Az = 'az',
  /** Belarusian */
  Be = 'be',
  /** Bulgarian */
  Bg = 'bg',
  /** Bambara */
  Bm = 'bm',
  /** Bangla */
  Bn = 'bn',
  /** Tibetan */
  Bo = 'bo',
  /** Breton */
  Br = 'br',
  /** Bosnian */
  Bs = 'bs',
  /** Catalan */
  Ca = 'ca',
  /** Chechen */
  Ce = 'ce',
  /** Corsican */
  Co = 'co',
  /** Czech */
  Cs = 'cs',
  /** Church Slavic */
  Cu = 'cu',
  /** Welsh */
  Cy = 'cy',
  /** Danish */
  Da = 'da',
  /** German */
  De = 'de',
  /** Austrian German */
  DeAt = 'de_AT',
  /** Swiss High German */
  DeCh = 'de_CH',
  /** Dzongkha */
  Dz = 'dz',
  /** Ewe */
  Ee = 'ee',
  /** Greek */
  El = 'el',
  /** English */
  En = 'en',
  /** Australian English */
  EnAu = 'en_AU',
  /** Canadian English */
  EnCa = 'en_CA',
  /** British English */
  EnGb = 'en_GB',
  /** American English */
  EnUs = 'en_US',
  /** Esperanto */
  Eo = 'eo',
  /** Spanish */
  Es = 'es',
  /** European Spanish */
  EsEs = 'es_ES',
  /** Mexican Spanish */
  EsMx = 'es_MX',
  /** Estonian */
  Et = 'et',
  /** Basque */
  Eu = 'eu',
  /** Persian */
  Fa = 'fa',
  /** Dari */
  FaAf = 'fa_AF',
  /** Fulah */
  Ff = 'ff',
  /** Finnish */
  Fi = 'fi',
  /** Faroese */
  Fo = 'fo',
  /** French */
  Fr = 'fr',
  /** Canadian French */
  FrCa = 'fr_CA',
  /** Swiss French */
  FrCh = 'fr_CH',
  /** Western Frisian */
  Fy = 'fy',
  /** Irish */
  Ga = 'ga',
  /** Scottish Gaelic */
  Gd = 'gd',
  /** Galician */
  Gl = 'gl',
  /** Gujarati */
  Gu = 'gu',
  /** Manx */
  Gv = 'gv',
  /** Hausa */
  Ha = 'ha',
  /** Hebrew */
  He = 'he',
  /** Hindi */
  Hi = 'hi',
  /** Croatian */
  Hr = 'hr',
  /** Haitian Creole */
  Ht = 'ht',
  /** Hungarian */
  Hu = 'hu',
  /** Armenian */
  Hy = 'hy',
  /** Interlingua */
  Ia = 'ia',
  /** Indonesian */
  Id = 'id',
  /** Igbo */
  Ig = 'ig',
  /** Sichuan Yi */
  Ii = 'ii',
  /** Icelandic */
  Is = 'is',
  /** Italian */
  It = 'it',
  /** Japanese */
  Ja = 'ja',
  /** Javanese */
  Jv = 'jv',
  /** Georgian */
  Ka = 'ka',
  /** Kikuyu */
  Ki = 'ki',
  /** Kazakh */
  Kk = 'kk',
  /** Kalaallisut */
  Kl = 'kl',
  /** Khmer */
  Km = 'km',
  /** Kannada */
  Kn = 'kn',
  /** Korean */
  Ko = 'ko',
  /** Kashmiri */
  Ks = 'ks',
  /** Kurdish */
  Ku = 'ku',
  /** Cornish */
  Kw = 'kw',
  /** Kyrgyz */
  Ky = 'ky',
  /** Latin */
  La = 'la',
  /** Luxembourgish */
  Lb = 'lb',
  /** Ganda */
  Lg = 'lg',
  /** Lingala */
  Ln = 'ln',
  /** Lao */
  Lo = 'lo',
  /** Lithuanian */
  Lt = 'lt',
  /** Luba-Katanga */
  Lu = 'lu',
  /** Latvian */
  Lv = 'lv',
  /** Malagasy */
  Mg = 'mg',
  /** Maori */
  Mi = 'mi',
  /** Macedonian */
  Mk = 'mk',
  /** Malayalam */
  Ml = 'ml',
  /** Mongolian */
  Mn = 'mn',
  /** Marathi */
  Mr = 'mr',
  /** Malay */
  Ms = 'ms',
  /** Maltese */
  Mt = 'mt',
  /** Burmese */
  My = 'my',
  /** Norwegian Bokmål */
  Nb = 'nb',
  /** North Ndebele */
  Nd = 'nd',
  /** Nepali */
  Ne = 'ne',
  /** Dutch */
  Nl = 'nl',
  /** Flemish */
  NlBe = 'nl_BE',
  /** Norwegian Nynorsk */
  Nn = 'nn',
  /** Nyanja */
  Ny = 'ny',
  /** Oromo */
  Om = 'om',
  /** Odia */
  Or = 'or',
  /** Ossetic */
  Os = 'os',
  /** Punjabi */
  Pa = 'pa',
  /** Polish */
  Pl = 'pl',
  /** Pashto */
  Ps = 'ps',
  /** Portuguese */
  Pt = 'pt',
  /** Brazilian Portuguese */
  PtBr = 'pt_BR',
  /** European Portuguese */
  PtPt = 'pt_PT',
  /** Quechua */
  Qu = 'qu',
  /** Romansh */
  Rm = 'rm',
  /** Rundi */
  Rn = 'rn',
  /** Romanian */
  Ro = 'ro',
  /** Moldavian */
  RoMd = 'ro_MD',
  /** Russian */
  Ru = 'ru',
  /** Kinyarwanda */
  Rw = 'rw',
  /** Sanskrit */
  Sa = 'sa',
  /** Sindhi */
  Sd = 'sd',
  /** Northern Sami */
  Se = 'se',
  /** Sango */
  Sg = 'sg',
  /** Sinhala */
  Si = 'si',
  /** Slovak */
  Sk = 'sk',
  /** Slovenian */
  Sl = 'sl',
  /** Samoan */
  Sm = 'sm',
  /** Shona */
  Sn = 'sn',
  /** Somali */
  So = 'so',
  /** Albanian */
  Sq = 'sq',
  /** Serbian */
  Sr = 'sr',
  /** Southern Sotho */
  St = 'st',
  /** Sundanese */
  Su = 'su',
  /** Swedish */
  Sv = 'sv',
  /** Swahili */
  Sw = 'sw',
  /** Congo Swahili */
  SwCd = 'sw_CD',
  /** Tamil */
  Ta = 'ta',
  /** Telugu */
  Te = 'te',
  /** Tajik */
  Tg = 'tg',
  /** Thai */
  Th = 'th',
  /** Tigrinya */
  Ti = 'ti',
  /** Turkmen */
  Tk = 'tk',
  /** Tongan */
  To = 'to',
  /** Turkish */
  Tr = 'tr',
  /** Tatar */
  Tt = 'tt',
  /** Uyghur */
  Ug = 'ug',
  /** Ukrainian */
  Uk = 'uk',
  /** Urdu */
  Ur = 'ur',
  /** Uzbek */
  Uz = 'uz',
  /** Vietnamese */
  Vi = 'vi',
  /** Volapük */
  Vo = 'vo',
  /** Wolof */
  Wo = 'wo',
  /** Xhosa */
  Xh = 'xh',
  /** Yiddish */
  Yi = 'yi',
  /** Yoruba */
  Yo = 'yo',
  /** Chinese */
  Zh = 'zh',
  /** Simplified Chinese */
  ZhHans = 'zh_Hans',
  /** Traditional Chinese */
  ZhHant = 'zh_Hant',
  /** Zulu */
  Zu = 'zu'
}

export type LocaleStringCustomFieldConfig = CustomField & {
  __typename?: 'LocaleStringCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  length?: Maybe<Scalars['Int']>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  pattern?: Maybe<Scalars['String']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type LocaleTextCustomFieldConfig = CustomField & {
  __typename?: 'LocaleTextCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type LocalizedString = {
  __typename?: 'LocalizedString';
  languageCode: LanguageCode;
  value: Scalars['String'];
};

export enum LogicalOperator {
  And = 'AND',
  Or = 'OR'
}

export type MagicLinkLoginInput = {
  code: Scalars['String'];
  emailAddress: Scalars['String'];
};

export type MaxTotalResultType = {
  __typename?: 'MaxTotalResultType';
  id: Scalars['ID'];
  label: Scalars['String'];
  max?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type MembershipTransitionError = ErrorResult & {
  __typename?: 'MembershipTransitionError';
  errorCode: ErrorCode;
  fromState: RsvMembershipStatus;
  message: Scalars['String'];
  toState: RsvMembershipStatus;
  transitionError: Scalars['String'];
};

export type MissingLoginStrategyError = ErrorResult & {
  __typename?: 'MissingLoginStrategyError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when attempting to register or verify a customer account without a password, when one is required. */
export type MissingPasswordError = ErrorResult & {
  __typename?: 'MissingPasswordError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a group with the current logged in user as administrator of the group */
  addCustomerToMyCustomerManagedGroup: CustomerManagedGroup;
  /** Adds an item to the order. If custom fields are defined on the OrderLine entity, a third argument 'customFields' will be available. */
  addItemToOrder: UpdateOrderItemsResult;
  /** Add a Payment to the Order */
  addPaymentToOrder: AddPaymentToOrderResult;
  /** Adjusts an OrderLine. If custom fields are defined on the OrderLine entity, a third argument 'customFields' of type `OrderLineCustomFieldsInput` will be available. */
  adjustOrderLine: UpdateOrderItemsResult;
  /** Applies the given coupon code to the active Order */
  applyCouponCode: ApplyCouponCodeResult;
  assignCustomerToOrderLine: OrderLine;
  /** Authenticates the user using a named authentication strategy */
  authenticate: AuthenticationResult;
  /** Create a new Customer Address */
  createCustomerAddress: Address;
  /** Create an empty group with the current user as Administrator */
  createCustomerManagedGroup: CustomerManagedGroup;
  createStripePaymentIntent?: Maybe<Scalars['String']>;
  createStripeSubscriptionIntent: StripeSubscriptionIntent;
  /** Delete an existing Address */
  deleteCustomerAddress: Success;
  /**
   * Authenticates the user using the native authentication strategy. This mutation is an alias for authenticate({ native: { ... }})
   *
   * The `rememberMe` option applies when using cookie-based sessions, and if `true` it will set the maxAge of the session cookie
   * to 1 year.
   */
  login: NativeAuthenticationResult;
  /** End the current authenticated session */
  logout: Success;
  makeCustomerAdminOfCustomerManagedGroup: CustomerManagedGroup;
  /** Regenerate and send a verification token for a new Customer registration. Only applicable if `authOptions.requireVerification` is set to true. */
  refreshCustomerVerification: RefreshCustomerVerificationResult;
  refundAcceptBlueTransaction: AcceptBlueRefundResult;
  /**
   * Register a Customer account with the given credentials. There are three possible registration flows:
   *
   * _If `authOptions.requireVerification` is set to `true`:_
   *
   * 1. **The Customer is registered _with_ a password**. A verificationToken will be created (and typically emailed to the Customer). That
   *    verificationToken would then be passed to the `verifyCustomerAccount` mutation _without_ a password. The Customer is then
   *    verified and authenticated in one step.
   * 2. **The Customer is registered _without_ a password**. A verificationToken will be created (and typically emailed to the Customer). That
   *    verificationToken would then be passed to the `verifyCustomerAccount` mutation _with_ the chosen password of the Customer. The Customer is then
   *    verified and authenticated in one step.
   *
   * _If `authOptions.requireVerification` is set to `false`:_
   *
   * 3. The Customer _must_ be registered _with_ a password. No further action is needed - the Customer is able to authenticate immediately.
   */
  registerCustomerAccount: RegisterCustomerAccountResult;
  /** Remove all OrderLine from the Order */
  removeAllOrderLines: RemoveOrderItemsResult;
  /** Removes the given coupon code from the active Order */
  removeCouponCode?: Maybe<Order>;
  removeCustomerFromMyCustomerManagedGroup: CustomerManagedGroup;
  /** Remove an OrderLine from the Order */
  removeOrderLine: RemoveOrderItemsResult;
  /** Requests a password reset email to be sent */
  requestPasswordReset?: Maybe<RequestPasswordResetResult>;
  /**
   * Request to update the emailAddress of the active Customer. If `authOptions.requireVerification` is enabled
   * (as is the default), then the `identifierChangeToken` will be assigned to the current User and
   * a IdentifierChangeRequestEvent will be raised. This can then be used e.g. by the EmailPlugin to email
   * that verification token to the Customer, which is then used to verify the change of email address.
   */
  requestUpdateCustomerEmailAddress: RequestUpdateCustomerEmailAddressResult;
  /** Resets a Customer's password based on the provided token */
  resetPassword: ResetPasswordResult;
  rsv_addPickupCode: RsvCreatePickupCodeMutationResult;
  rsv_addToWaitingList: RsvWaitingListParticipant;
  rsv_assignClassGroupToOrderLine: UpdateOrderItemsResult;
  rsv_checkinKioskParticipant: RsvCheckinParticipantMutationResult;
  rsv_checkinParticipant: RsvCheckinParticipantMutationResult;
  rsv_pauseMembership: RsvMembershipMutationResult;
  rsv_stopMembership: RsvMembershipMutationResult;
  rsv_unpauseMembership: RsvMembershipMutationResult;
  rsv_updatePickupCode: RsvPickupCodeMutationResult;
  sendLoginEmail: SendLoginEmailMutationResult;
  /** Set the Customer for the Order. Required only if the Customer is not currently logged in */
  setCustomerForOrder: SetCustomerForOrderResult;
  setCustomerImage?: Maybe<Asset>;
  /** Sets the billing address for this order */
  setOrderBillingAddress: ActiveOrderResult;
  /** Allows any custom fields to be set for the active order */
  setOrderCustomFields: ActiveOrderResult;
  /** Sets the shipping address for this order */
  setOrderShippingAddress: ActiveOrderResult;
  /**
   * Sets the shipping method by id, which can be obtained with the `eligibleShippingMethods` query.
   * An Order can have multiple shipping methods, in which case you can pass an array of ids. In this case,
   * you should configure a custom ShippingLineAssignmentStrategy in order to know which OrderLines each
   * shipping method will apply to.
   */
  setOrderShippingMethod: SetOrderShippingMethodResult;
  submitProductReview: ProductReview;
  /** Transitions an Order to a new state. Valid next states can be found by querying `nextOrderStates` */
  transitionOrderToState?: Maybe<TransitionOrderToStateResult>;
  /** Update an existing Customer */
  updateCustomer: Customer;
  /** Update an existing Address */
  updateCustomerAddress: Address;
  /**
   * Confirm the update of the emailAddress with the provided token, which has been generated by the
   * `requestUpdateCustomerEmailAddress` mutation.
   */
  updateCustomerEmailAddress: UpdateCustomerEmailAddressResult;
  /** Update a member of one's group */
  updateCustomerManagedGroupMember: CustomerManagedGroup;
  /** Update the password of the active Customer */
  updateCustomerPassword: UpdateCustomerPasswordResult;
  /**
   * Verify a Customer email address with the token sent to that address. Only applicable if `authOptions.requireVerification` is set to true.
   *
   * If the Customer was not registered with a password in the `registerCustomerAccount` mutation, the password _must_ be
   * provided here.
   */
  verifyCustomerAccount: VerifyCustomerAccountResult;
  voteOnReview: ProductReview;
};


export type MutationAddCustomerToMyCustomerManagedGroupArgs = {
  input?: InputMaybe<AddCustomerToMyCustomerManagedGroupInput>;
};


export type MutationAddItemToOrderArgs = {
  customFields?: InputMaybe<OrderLineCustomFieldsInput>;
  productVariantId: Scalars['ID'];
  quantity: Scalars['Int'];
};


export type MutationAddPaymentToOrderArgs = {
  input: PaymentInput;
};


export type MutationAdjustOrderLineArgs = {
  customFields?: InputMaybe<OrderLineCustomFieldsInput>;
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
};


export type MutationApplyCouponCodeArgs = {
  couponCode: Scalars['String'];
};


export type MutationAssignCustomerToOrderLineArgs = {
  customerId: Scalars['ID'];
  orderLineId: Scalars['ID'];
};


export type MutationAuthenticateArgs = {
  input: AuthenticationInput;
  rememberMe?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateCustomerAddressArgs = {
  input: CreateAddressInput;
};


export type MutationDeleteCustomerAddressArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  rememberMe?: InputMaybe<Scalars['Boolean']>;
  username: Scalars['String'];
};


export type MutationMakeCustomerAdminOfCustomerManagedGroupArgs = {
  customerId: Scalars['ID'];
  groupId: Scalars['ID'];
};


export type MutationRefreshCustomerVerificationArgs = {
  emailAddress: Scalars['String'];
};


export type MutationRefundAcceptBlueTransactionArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  cvv2?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['Int'];
};


export type MutationRegisterCustomerAccountArgs = {
  input: RegisterCustomerInput;
};


export type MutationRemoveCouponCodeArgs = {
  couponCode: Scalars['String'];
};


export type MutationRemoveCustomerFromMyCustomerManagedGroupArgs = {
  customerId: Scalars['ID'];
};


export type MutationRemoveOrderLineArgs = {
  orderLineId: Scalars['ID'];
};


export type MutationRequestPasswordResetArgs = {
  emailAddress: Scalars['String'];
};


export type MutationRequestUpdateCustomerEmailAddressArgs = {
  newEmailAddress: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationRsv_AddPickupCodeArgs = {
  input: RsvPickupCodeInput;
};


export type MutationRsv_AddToWaitingListArgs = {
  clazzId: Scalars['ID'];
};


export type MutationRsv_AssignClassGroupToOrderLineArgs = {
  classGroupId: Scalars['ID'];
  orderLineId: Scalars['ID'];
  preferredTimeslotIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationRsv_CheckinKioskParticipantArgs = {
  input: RsvClassIntanceParticipantInput;
  token: Scalars['String'];
};


export type MutationRsv_CheckinParticipantArgs = {
  classInstanceId: Scalars['ID'];
  input: RsvClassIntanceParticipantInput;
  participantId: Scalars['ID'];
};


export type MutationRsv_UpdatePickupCodeArgs = {
  id: Scalars['ID'];
  input: RsvPickupCodeInput;
};


export type MutationSendLoginEmailArgs = {
  destination?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
};


export type MutationSetCustomerForOrderArgs = {
  input: CreateCustomerInput;
};


export type MutationSetCustomerImageArgs = {
  file: Scalars['Upload'];
};


export type MutationSetOrderBillingAddressArgs = {
  input: CreateAddressInput;
};


export type MutationSetOrderCustomFieldsArgs = {
  input: UpdateOrderInput;
};


export type MutationSetOrderShippingAddressArgs = {
  input: CreateAddressInput;
};


export type MutationSetOrderShippingMethodArgs = {
  shippingMethodId: Array<Scalars['ID']>;
};


export type MutationSubmitProductReviewArgs = {
  input: SubmitProductReviewInput;
};


export type MutationTransitionOrderToStateArgs = {
  state: Scalars['String'];
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateCustomerAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateCustomerEmailAddressArgs = {
  token: Scalars['String'];
};


export type MutationUpdateCustomerManagedGroupMemberArgs = {
  input: UpdateCustomerManagedGroupMemberInput;
};


export type MutationUpdateCustomerPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationVerifyCustomerAccountArgs = {
  password?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


export type MutationVoteOnReviewArgs = {
  id: Scalars['ID'];
  vote: Scalars['Boolean'];
};

export type NativeAuthInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

/** Returned when attempting an operation that relies on the NativeAuthStrategy, if that strategy is not configured. */
export type NativeAuthStrategyError = ErrorResult & {
  __typename?: 'NativeAuthStrategyError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type NativeAuthenticationResult = CurrentUser | InvalidCredentialsError | NativeAuthStrategyError | NotVerifiedError;

/** Returned when attempting to set a negative OrderLine quantity. */
export type NegativeQuantityError = ErrorResult & {
  __typename?: 'NegativeQuantityError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/**
 * Returned when invoking a mutation which depends on there being an active Order on the
 * current session.
 */
export type NoActiveOrderError = ErrorResult & {
  __typename?: 'NoActiveOrderError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

/**
 * Returned if `authOptions.requireVerification` is set to `true` (which is the default)
 * and an unverified user attempts to authenticate.
 */
export type NotVerifiedError = ErrorResult & {
  __typename?: 'NotVerifiedError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export enum NotificationType {
  History = 'HISTORY',
  Note = 'NOTE',
  Notification = 'NOTIFICATION'
}

/** Operators for filtering on a list of Number fields */
export type NumberListOperators = {
  inList: Scalars['Float'];
};

/** Operators for filtering on a Int or Float field */
export type NumberOperators = {
  between?: InputMaybe<NumberRange>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
};

export type NumberRange = {
  end: Scalars['Float'];
  start: Scalars['Float'];
};

export type Order = Node & {
  __typename?: 'Order';
  /** An order is active as long as the payment process has not been completed */
  active: Scalars['Boolean'];
  billingAddress?: Maybe<OrderAddress>;
  /** A unique code for the Order */
  code: Scalars['String'];
  /** An array of all coupon codes applied to the Order */
  couponCodes: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currencyCode: CurrencyCode;
  customFields?: Maybe<OrderCustomFields>;
  customer?: Maybe<Customer>;
  discounts: Array<Discount>;
  fulfillments?: Maybe<Array<Fulfillment>>;
  history: HistoryEntryList;
  id: Scalars['ID'];
  lines: Array<OrderLine>;
  /**
   * The date & time that the Order was placed, i.e. the Customer
   * completed the checkout and the Order is no longer "active"
   */
  orderPlacedAt?: Maybe<Scalars['DateTime']>;
  payments?: Maybe<Array<Payment>>;
  /** Promotions applied to the order. Only gets populated after the payment process has completed. */
  promotions: Array<Promotion>;
  shipping: Scalars['Money'];
  shippingAddress?: Maybe<OrderAddress>;
  shippingLines: Array<ShippingLine>;
  shippingWithTax: Scalars['Money'];
  state: Scalars['String'];
  /**
   * The subTotal is the total of all OrderLines in the Order. This figure also includes any Order-level
   * discounts which have been prorated (proportionally distributed) amongst the items of each OrderLine.
   * To get a total of all OrderLines which does not account for prorated discounts, use the
   * sum of `OrderLine.discountedLinePrice` values.
   */
  subTotal: Scalars['Money'];
  /** Same as subTotal, but inclusive of tax */
  subTotalWithTax: Scalars['Money'];
  /**
   * Surcharges are arbitrary modifications to the Order total which are neither
   * ProductVariants nor discounts resulting from applied Promotions. For example,
   * one-off discounts based on customer interaction, or surcharges based on payment
   * methods.
   */
  surcharges: Array<Surcharge>;
  /** A summary of the taxes being applied to this Order */
  taxSummary: Array<OrderTaxSummary>;
  /** Equal to subTotal plus shipping */
  total: Scalars['Money'];
  totalQuantity: Scalars['Int'];
  /** The final payable amount. Equal to subTotalWithTax plus shippingWithTax */
  totalWithTax: Scalars['Money'];
  type: OrderType;
  updatedAt: Scalars['DateTime'];
};


export type OrderHistoryArgs = {
  options?: InputMaybe<HistoryEntryListOptions>;
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  customFields?: Maybe<Scalars['JSON']>;
  fullName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  streetLine1?: Maybe<Scalars['String']>;
  streetLine2?: Maybe<Scalars['String']>;
};

export type OrderCustomFields = {
  __typename?: 'OrderCustomFields';
  paymentMethodCode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type OrderFilterParameter = {
  _and?: InputMaybe<Array<OrderFilterParameter>>;
  _or?: InputMaybe<Array<OrderFilterParameter>>;
  active?: InputMaybe<BooleanOperators>;
  code?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  currencyCode?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  orderPlacedAt?: InputMaybe<DateOperators>;
  paymentMethodCode?: InputMaybe<StringOperators>;
  shipping?: InputMaybe<NumberOperators>;
  shippingWithTax?: InputMaybe<NumberOperators>;
  state?: InputMaybe<StringOperators>;
  subTotal?: InputMaybe<NumberOperators>;
  subTotalWithTax?: InputMaybe<NumberOperators>;
  timezone?: InputMaybe<StringOperators>;
  total?: InputMaybe<NumberOperators>;
  totalQuantity?: InputMaybe<NumberOperators>;
  totalWithTax?: InputMaybe<NumberOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

/** Returned when the maximum order size limit has been reached. */
export type OrderLimitError = ErrorResult & {
  __typename?: 'OrderLimitError';
  errorCode: ErrorCode;
  maxItems: Scalars['Int'];
  message: Scalars['String'];
};

export type OrderLine = Node & {
  __typename?: 'OrderLine';
  acceptBlueSubscriptions: Array<AcceptBlueSubscription>;
  agreement?: Maybe<Agreement>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<OrderLineCustomFields>;
  /** The price of the line including discounts, excluding tax */
  discountedLinePrice: Scalars['Money'];
  /** The price of the line including discounts and tax */
  discountedLinePriceWithTax: Scalars['Money'];
  /**
   * The price of a single unit including discounts, excluding tax.
   *
   * If Order-level discounts have been applied, this will not be the
   * actual taxable unit price (see `proratedUnitPrice`), but is generally the
   * correct price to display to customers to avoid confusion
   * about the internal handling of distributed Order-level discounts.
   */
  discountedUnitPrice: Scalars['Money'];
  /** The price of a single unit including discounts and tax */
  discountedUnitPriceWithTax: Scalars['Money'];
  discounts: Array<Discount>;
  featuredAsset?: Maybe<Asset>;
  fulfillmentLines?: Maybe<Array<FulfillmentLine>>;
  id: Scalars['ID'];
  isClass: Scalars['Boolean'];
  isSubscription: Scalars['Boolean'];
  isTrial: Scalars['Boolean'];
  /** The total price of the line excluding tax and discounts. */
  linePrice: Scalars['Money'];
  /** The total price of the line including tax but excluding discounts. */
  linePriceWithTax: Scalars['Money'];
  /** The total tax on this line */
  lineTax: Scalars['Money'];
  order: Order;
  /** The quantity at the time the Order was placed */
  orderPlacedQuantity: Scalars['Int'];
  productVariant: ProductVariant;
  /**
   * The actual line price, taking into account both item discounts _and_ prorated (proportionally-distributed)
   * Order-level discounts. This value is the true economic value of the OrderLine, and is used in tax
   * and refund calculations.
   */
  proratedLinePrice: Scalars['Money'];
  /** The proratedLinePrice including tax */
  proratedLinePriceWithTax: Scalars['Money'];
  /**
   * The actual unit price, taking into account both item discounts _and_ prorated (proportionally-distributed)
   * Order-level discounts. This value is the true economic value of the OrderItem, and is used in tax
   * and refund calculations.
   */
  proratedUnitPrice: Scalars['Money'];
  /** The proratedUnitPrice including tax */
  proratedUnitPriceWithTax: Scalars['Money'];
  /** The quantity of items purchased */
  quantity: Scalars['Int'];
  /**
   * These subscriptions are calculated dynamically, and should not be used for historical data.
   * Actual created subscriptions should be fetched from he connected Stripe account.
   */
  stripeSubscriptions?: Maybe<Array<StripeSubscription>>;
  subscriptionDetails?: Maybe<SubscriptionDetails>;
  subscriptions: Array<Subscription>;
  taxLines: Array<TaxLine>;
  taxRate: Scalars['Float'];
  /** The price of a single unit, excluding tax and discounts */
  unitPrice: Scalars['Money'];
  /** Non-zero if the unitPrice has changed since it was initially added to Order */
  unitPriceChangeSinceAdded: Scalars['Money'];
  /** The price of a single unit, including tax but excluding discounts */
  unitPriceWithTax: Scalars['Money'];
  /** Non-zero if the unitPriceWithTax has changed since it was initially added to Order */
  unitPriceWithTaxChangeSinceAdded: Scalars['Money'];
  updatedAt: Scalars['DateTime'];
};

export type OrderLineCustomFields = {
  __typename?: 'OrderLineCustomFields';
  EndDate?: Maybe<Scalars['DateTime']>;
  acceptBlueSubscriptionIds?: Maybe<Array<Scalars['Int']>>;
  agreement?: Maybe<Agreement>;
  assignedCustomer?: Maybe<Customer>;
  autoRenew?: Maybe<Scalars['Boolean']>;
  chargeDateOffset?: Maybe<Scalars['Int']>;
  downpayment?: Maybe<Scalars['Int']>;
  downpaymentIsRegistration?: Maybe<Scalars['Boolean']>;
  gracePeriodInDays?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['String']>;
  intervalCount?: Maybe<Scalars['Int']>;
  intervalStartDate?: Maybe<Scalars['DateTime']>;
  membershipStatus?: Maybe<Scalars['String']>;
  onetimeDiscount?: Maybe<Scalars['Int']>;
  participationStartDate?: Maybe<Scalars['DateTime']>;
  pausedUntil?: Maybe<Scalars['DateTime']>;
  paymentInterval?: Maybe<Scalars['String']>;
  preferredGroup?: Maybe<RsvClassGroup>;
  preferredTimeslotIds?: Maybe<Array<Scalars['String']>>;
  recurringChargeDay?: Maybe<Scalars['DateTime']>;
  recurringDiscount?: Maybe<Scalars['Int']>;
  recurringRegistrationDiscount?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type OrderLineCustomFieldsInput = {
  EndDate?: InputMaybe<Scalars['DateTime']>;
  acceptBlueSubscriptionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  agreementId?: InputMaybe<Scalars['ID']>;
  assignedCustomerId?: InputMaybe<Scalars['ID']>;
  autoRenew?: InputMaybe<Scalars['Boolean']>;
  chargeDateOffset?: InputMaybe<Scalars['Int']>;
  downpayment?: InputMaybe<Scalars['Int']>;
  downpaymentIsRegistration?: InputMaybe<Scalars['Boolean']>;
  gracePeriodInDays?: InputMaybe<Scalars['Int']>;
  interval?: InputMaybe<Scalars['String']>;
  intervalCount?: InputMaybe<Scalars['Int']>;
  intervalStartDate?: InputMaybe<Scalars['DateTime']>;
  membershipStatus?: InputMaybe<Scalars['String']>;
  onetimeDiscount?: InputMaybe<Scalars['Int']>;
  participationStartDate?: InputMaybe<Scalars['DateTime']>;
  pausedUntil?: InputMaybe<Scalars['DateTime']>;
  paymentInterval?: InputMaybe<Scalars['String']>;
  preferredGroupId?: InputMaybe<Scalars['ID']>;
  preferredTimeslotIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  recurringChargeDay?: InputMaybe<Scalars['DateTime']>;
  recurringDiscount?: InputMaybe<Scalars['Int']>;
  recurringRegistrationDiscount?: InputMaybe<Scalars['Int']>;
  renewalDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type OrderList = PaginatedList & {
  __typename?: 'OrderList';
  items: Array<Order>;
  totalItems: Scalars['Int'];
};

export type OrderListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<OrderFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<OrderSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

/** Returned when attempting to modify the contents of an Order that is not in the `AddingItems` state. */
export type OrderModificationError = ErrorResult & {
  __typename?: 'OrderModificationError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when attempting to add a Payment to an Order that is not in the `ArrangingPayment` state. */
export type OrderPaymentStateError = ErrorResult & {
  __typename?: 'OrderPaymentStateError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type OrderSortParameter = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  orderPlacedAt?: InputMaybe<SortOrder>;
  paymentMethodCode?: InputMaybe<SortOrder>;
  shipping?: InputMaybe<SortOrder>;
  shippingWithTax?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  subTotal?: InputMaybe<SortOrder>;
  subTotalWithTax?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  total?: InputMaybe<SortOrder>;
  totalQuantity?: InputMaybe<SortOrder>;
  totalWithTax?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Returned if there is an error in transitioning the Order state */
export type OrderStateTransitionError = ErrorResult & {
  __typename?: 'OrderStateTransitionError';
  errorCode: ErrorCode;
  fromState: Scalars['String'];
  message: Scalars['String'];
  toState: Scalars['String'];
  transitionError: Scalars['String'];
};

/**
 * A summary of the taxes being applied to this order, grouped
 * by taxRate.
 */
export type OrderTaxSummary = {
  __typename?: 'OrderTaxSummary';
  /** A description of this tax */
  description: Scalars['String'];
  /** The total net price of OrderLines to which this taxRate applies */
  taxBase: Scalars['Money'];
  /** The taxRate as a percentage */
  taxRate: Scalars['Float'];
  /** The total tax being applied to the Order at this taxRate */
  taxTotal: Scalars['Money'];
};

export enum OrderType {
  Aggregate = 'Aggregate',
  Regular = 'Regular',
  Seller = 'Seller'
}

export type PaginatedList = {
  items: Array<Node>;
  totalItems: Scalars['Int'];
};

/** Returned when attempting to verify a customer account with a password, when a password has already been set. */
export type PasswordAlreadySetError = ErrorResult & {
  __typename?: 'PasswordAlreadySetError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/**
 * Returned if the token used to reset a Customer's password is valid, but has
 * expired according to the `verificationTokenDuration` setting in the AuthOptions.
 */
export type PasswordResetTokenExpiredError = ErrorResult & {
  __typename?: 'PasswordResetTokenExpiredError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/**
 * Returned if the token used to reset a Customer's password is either
 * invalid or does not match any expected tokens.
 */
export type PasswordResetTokenInvalidError = ErrorResult & {
  __typename?: 'PasswordResetTokenInvalidError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/** Returned when attempting to register or verify a customer account where the given password fails password validation. */
export type PasswordValidationError = ErrorResult & {
  __typename?: 'PasswordValidationError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  validationErrorMessage: Scalars['String'];
};

export type Payment = Node & {
  __typename?: 'Payment';
  amount: Scalars['Money'];
  createdAt: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  method: Scalars['String'];
  order: Order;
  orderId: Scalars['ID'];
  refunds: Array<Refund>;
  state: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Returned when a Payment is declined by the payment provider. */
export type PaymentDeclinedError = ErrorResult & {
  __typename?: 'PaymentDeclinedError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  paymentErrorMessage: Scalars['String'];
};

/** Returned when a Payment fails due to an error. */
export type PaymentFailedError = ErrorResult & {
  __typename?: 'PaymentFailedError';
  errorCode: ErrorCode;
  message: Scalars['String'];
  paymentErrorMessage: Scalars['String'];
};

export type PaymentFilterParameter = {
  _and?: InputMaybe<Array<PaymentFilterParameter>>;
  _or?: InputMaybe<Array<PaymentFilterParameter>>;
  amount?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  errorMessage?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  method?: InputMaybe<StringOperators>;
  orderId?: InputMaybe<IdOperators>;
  state?: InputMaybe<StringOperators>;
  transactionId?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

/** Passed as input to the `addPaymentToOrder` mutation. */
export type PaymentInput = {
  /**
   * This field should contain arbitrary data passed to the specified PaymentMethodHandler's `createPayment()` method
   * as the "metadata" argument. For example, it could contain an ID for the payment and other
   * data generated by the payment provider.
   */
  metadata: Scalars['JSON'];
  /** This field should correspond to the `code` property of a PaymentMethod. */
  method: Scalars['String'];
};

export enum PaymentInterval {
  Monthly = 'Monthly',
  Pif = 'PIF',
  Semiannually = 'Semiannually',
  Semimonthly = 'Semimonthly',
  Weekly = 'Weekly'
}

export type PaymentList = PaginatedList & {
  __typename?: 'PaymentList';
  items: Array<Payment>;
  totalItems: Scalars['Int'];
};

export type PaymentListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<PaymentFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<PaymentSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  checker?: Maybe<ConfigurableOperation>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<PaymentMethodCustomFields>;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  handler: ConfigurableOperation;
  id: Scalars['ID'];
  name: Scalars['String'];
  translations: Array<PaymentMethodTranslation>;
  updatedAt: Scalars['DateTime'];
};

export type PaymentMethodCustomFields = {
  __typename?: 'PaymentMethodCustomFields';
  acceptBlueTransactionRetrievelDate?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethodQuote = {
  __typename?: 'PaymentMethodQuote';
  acceptBlueHostedTokenizationKey?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  customFields?: Maybe<PaymentMethodCustomFields>;
  description: Scalars['String'];
  eligibilityMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEligible: Scalars['Boolean'];
  name: Scalars['String'];
  stripeSubscriptionPublishableKey?: Maybe<Scalars['String']>;
};

export type PaymentMethodTranslation = {
  __typename?: 'PaymentMethodTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentSortParameter = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  errorMessage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  method?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

/**
 * @description
 * Permissions for administrators and customers. Used to control access to
 * GraphQL resolvers via the {@link Allow} decorator.
 *
 * ## Understanding Permission.Owner
 *
 * `Permission.Owner` is a special permission which is used in some Vendure resolvers to indicate that that resolver should only
 * be accessible to the "owner" of that resource.
 *
 * For example, the Shop API `activeCustomer` query resolver should only return the Customer object for the "owner" of that Customer, i.e.
 * based on the activeUserId of the current session. As a result, the resolver code looks like this:
 *
 * @example
 * ```TypeScript
 * \@Query()
 * \@Allow(Permission.Owner)
 * async activeCustomer(\@Ctx() ctx: RequestContext): Promise<Customer | undefined> {
 *   const userId = ctx.activeUserId;
 *   if (userId) {
 *     return this.customerService.findOneByUserId(ctx, userId);
 *   }
 * }
 * ```
 *
 * Here we can see that the "ownership" must be enforced by custom logic inside the resolver. Since "ownership" cannot be defined generally
 * nor statically encoded at build-time, any resolvers using `Permission.Owner` **must** include logic to enforce that only the owner
 * of the resource has access. If not, then it is the equivalent of using `Permission.Public`.
 *
 *
 * @docsCategory common
 */
export enum Permission {
  /** Approves a participant on the waiting list */
  ApproveWaitingListParticipant = 'ApproveWaitingListParticipant',
  /** Can assign a school member to a clazz to become a participant */
  AssignParticipant = 'AssignParticipant',
  /** Can assign ranks to students */
  AssignRank = 'AssignRank',
  /** Authenticated means simply that the user is logged in */
  Authenticated = 'Authenticated',
  /** Can cancel a class */
  CancelClass = 'CancelClass',
  /** Can change the class participant graduation/fail status */
  ChangeStatusParticipant = 'ChangeStatusParticipant',
  /** Allows copying to existing channels based on template */
  ChannelCopyCreate = 'ChannelCopyCreate',
  /** Can assign class participation status of a school member */
  CheckinParticipant = 'CheckinParticipant',
  /** Grants permission to create Administrator */
  CreateAdministrator = 'CreateAdministrator',
  /** Allows create of Agreement */
  CreateAgreement = 'CreateAgreement',
  /** Grants permission to create Asset */
  CreateAsset = 'CreateAsset',
  /** Grants permission to create Products, Facets, Assets, Collections */
  CreateCatalog = 'CreateCatalog',
  /** Grants permission to create Channel */
  CreateChannel = 'CreateChannel',
  /** Grants permission to create Collection */
  CreateCollection = 'CreateCollection',
  /** Grants permission to create Country */
  CreateCountry = 'CreateCountry',
  /** Grants permission to create Customer */
  CreateCustomer = 'CreateCustomer',
  /** Grants permission to create CustomerGroup */
  CreateCustomerGroup = 'CreateCustomerGroup',
  /** Grants permission to create Facet */
  CreateFacet = 'CreateFacet',
  /** Assign customers active membership to customer */
  CreateMember = 'CreateMember',
  /** Grants permission to create Order */
  CreateOrder = 'CreateOrder',
  /** Grants permission to create PaymentMethod */
  CreatePaymentMethod = 'CreatePaymentMethod',
  /** Grants permission to create Product */
  CreateProduct = 'CreateProduct',
  /** Grants permission to create Promotion */
  CreatePromotion = 'CreatePromotion',
  /** Allows create of Blackout Dates */
  CreateRsvBlackoutDate = 'CreateRsvBlackoutDate',
  /** Allows create of Clazzes, Class Instances, Class Blackout dates, etc */
  CreateRsvClazz = 'CreateRsvClazz',
  /** Allows create of Instructors and Instructor Blackout dates */
  CreateRsvInstructor = 'CreateRsvInstructor',
  /** Allows the create of location based entities: Locations, ClassRooms, Location Blackout dates, Training Assets, etc */
  CreateRsvLocation = 'CreateRsvLocation',
  /** Allows create of Martial Art */
  CreateRsvMartialArt = 'CreateRsvMartialArt',
  /** Allows create of Membership */
  CreateRsvMembership = 'CreateRsvMembership',
  /** Allows create of Participant / Onboarding */
  CreateRsvOnboarding = 'CreateRsvOnboarding',
  /** Allows create of Participants */
  CreateRsvParticipant = 'CreateRsvParticipant',
  /** Allows create of Pickup Code */
  CreateRsvPickupCode = 'CreateRsvPickupCode',
  /** Allows create of Rank */
  CreateRsvRank = 'CreateRsvRank',
  /** Allows create of Schedules */
  CreateRsvSchedule = 'CreateRsvSchedule',
  /** Allows create of Staff and Staff Blackout dates */
  CreateRsvStaff = 'CreateRsvStaff',
  /** Allows create of Training Asset */
  CreateRsvTrainingAsset = 'CreateRsvTrainingAsset',
  /** Allows create of WaitingList */
  CreateRsvWaitingList = 'CreateRsvWaitingList',
  /** Grants permission to create Seller */
  CreateSeller = 'CreateSeller',
  /** Grants permission to create PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  CreateSettings = 'CreateSettings',
  /** Grants permission to create ShippingMethod */
  CreateShippingMethod = 'CreateShippingMethod',
  /** Grants permission to create StockLocation */
  CreateStockLocation = 'CreateStockLocation',
  /** Grants permission to create System */
  CreateSystem = 'CreateSystem',
  /** Grants permission to create Tag */
  CreateTag = 'CreateTag',
  /** Grants permission to create TaxCategory */
  CreateTaxCategory = 'CreateTaxCategory',
  /** Grants permission to create TaxRate */
  CreateTaxRate = 'CreateTaxRate',
  /** Grants permission to create Zone */
  CreateZone = 'CreateZone',
  /** Grants permission to delete Administrator */
  DeleteAdministrator = 'DeleteAdministrator',
  /** Allows delete of Agreement */
  DeleteAgreement = 'DeleteAgreement',
  /** Grants permission to delete Asset */
  DeleteAsset = 'DeleteAsset',
  /** Grants permission to delete Products, Facets, Assets, Collections */
  DeleteCatalog = 'DeleteCatalog',
  /** Grants permission to delete Channel */
  DeleteChannel = 'DeleteChannel',
  /** Grants permission to delete Collection */
  DeleteCollection = 'DeleteCollection',
  /** Grants permission to delete Country */
  DeleteCountry = 'DeleteCountry',
  /** Grants permission to delete Customer */
  DeleteCustomer = 'DeleteCustomer',
  /** Grants permission to delete CustomerGroup */
  DeleteCustomerGroup = 'DeleteCustomerGroup',
  /** Grants permission to delete Facet */
  DeleteFacet = 'DeleteFacet',
  /** Grants permission to delete Order */
  DeleteOrder = 'DeleteOrder',
  /** Grants permission to delete PaymentMethod */
  DeletePaymentMethod = 'DeletePaymentMethod',
  /** Grants permission to delete Product */
  DeleteProduct = 'DeleteProduct',
  /** Grants permission to delete Promotion */
  DeletePromotion = 'DeletePromotion',
  /** Allows delete of Blackout Dates */
  DeleteRsvBlackoutDate = 'DeleteRsvBlackoutDate',
  /** Allows delete of Clazzes, Class Instances, Class Blackout dates, etc */
  DeleteRsvClazz = 'DeleteRsvClazz',
  /** Allows delete of Instructors and Instructor Blackout dates */
  DeleteRsvInstructor = 'DeleteRsvInstructor',
  /** Allows the delete of location based entities: Locations, ClassRooms, Location Blackout dates, Training Assets, etc */
  DeleteRsvLocation = 'DeleteRsvLocation',
  /** Allows delete of Martial Art */
  DeleteRsvMartialArt = 'DeleteRsvMartialArt',
  /** Allows delete of Membership */
  DeleteRsvMembership = 'DeleteRsvMembership',
  /** Allows delete of Participant / Onboarding */
  DeleteRsvOnboarding = 'DeleteRsvOnboarding',
  /** Allows delete of Participants */
  DeleteRsvParticipant = 'DeleteRsvParticipant',
  /** Allows delete of Pickup Code */
  DeleteRsvPickupCode = 'DeleteRsvPickupCode',
  /** Allows delete of Rank */
  DeleteRsvRank = 'DeleteRsvRank',
  /** Allows delete of Schedules */
  DeleteRsvSchedule = 'DeleteRsvSchedule',
  /** Allows delete of Staff and Staff Blackout dates */
  DeleteRsvStaff = 'DeleteRsvStaff',
  /** Allows delete of Training Asset */
  DeleteRsvTrainingAsset = 'DeleteRsvTrainingAsset',
  /** Allows delete of WaitingList */
  DeleteRsvWaitingList = 'DeleteRsvWaitingList',
  /** Grants permission to delete Seller */
  DeleteSeller = 'DeleteSeller',
  /** Grants permission to delete PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  DeleteSettings = 'DeleteSettings',
  /** Grants permission to delete ShippingMethod */
  DeleteShippingMethod = 'DeleteShippingMethod',
  /** Grants permission to delete StockLocation */
  DeleteStockLocation = 'DeleteStockLocation',
  /** Grants permission to delete System */
  DeleteSystem = 'DeleteSystem',
  /** Grants permission to delete Tag */
  DeleteTag = 'DeleteTag',
  /** Grants permission to delete TaxCategory */
  DeleteTaxCategory = 'DeleteTaxCategory',
  /** Grants permission to delete TaxRate */
  DeleteTaxRate = 'DeleteTaxRate',
  /** Grants permission to delete Zone */
  DeleteZone = 'DeleteZone',
  /** Allows instructors to edit certain data. Primarily intended for them to edit themselves */
  EditInstructorSelf = 'EditInstructorSelf',
  /** Allows adding notes about Instructors */
  EvaluateInstructor = 'EvaluateInstructor',
  /** Can add review notes about a class participant */
  EvaluateParticipant = 'EvaluateParticipant',
  /** Can list all channels */
  ListChannels = 'ListChannels',
  /** Who may send messages to a class participant */
  MessageParticipant = 'MessageParticipant',
  /** Owner means the user owns this entity, e.g. a Customer's own Order */
  Owner = 'Owner',
  /** Public means any unauthenticated user may perform the operation */
  Public = 'Public',
  /** Grants permission to read Administrator */
  ReadAdministrator = 'ReadAdministrator',
  /** Allows read of Agreement */
  ReadAgreement = 'ReadAgreement',
  /** Grants permission to read Asset */
  ReadAsset = 'ReadAsset',
  /** Grants permission to read Products, Facets, Assets, Collections */
  ReadCatalog = 'ReadCatalog',
  /** Grants permission to read Channel */
  ReadChannel = 'ReadChannel',
  /** Grants permission to read Collection */
  ReadCollection = 'ReadCollection',
  /** Grants permission to read Country */
  ReadCountry = 'ReadCountry',
  /** Grants permission to read Customer */
  ReadCustomer = 'ReadCustomer',
  /** Grants permission to read CustomerGroup */
  ReadCustomerGroup = 'ReadCustomerGroup',
  /** Grants permission to read Facet */
  ReadFacet = 'ReadFacet',
  /** Grants permission to read Order */
  ReadOrder = 'ReadOrder',
  /** Grants permission to read PaymentMethod */
  ReadPaymentMethod = 'ReadPaymentMethod',
  /** Grants permission to read Product */
  ReadProduct = 'ReadProduct',
  /** Grants permission to read Promotion */
  ReadPromotion = 'ReadPromotion',
  /** Allows read of Blackout Dates */
  ReadRsvBlackoutDate = 'ReadRsvBlackoutDate',
  /** Allows read of Clazzes, Class Instances, Class Blackout dates, etc */
  ReadRsvClazz = 'ReadRsvClazz',
  /** Allows read of Instructors and Instructor Blackout dates */
  ReadRsvInstructor = 'ReadRsvInstructor',
  /** Allows the read of location based entities: Locations, ClassRooms, Location Blackout dates, Training Assets, etc */
  ReadRsvLocation = 'ReadRsvLocation',
  /** Allows read of Martial Art */
  ReadRsvMartialArt = 'ReadRsvMartialArt',
  /** Allows read of Membership */
  ReadRsvMembership = 'ReadRsvMembership',
  /** Allows read of Participant / Onboarding */
  ReadRsvOnboarding = 'ReadRsvOnboarding',
  /** Allows read of Participants */
  ReadRsvParticipant = 'ReadRsvParticipant',
  /** Allows read of Pickup Code */
  ReadRsvPickupCode = 'ReadRsvPickupCode',
  /** Allows read of Rank */
  ReadRsvRank = 'ReadRsvRank',
  /** Allows read of Schedules */
  ReadRsvSchedule = 'ReadRsvSchedule',
  /** Allows read of Staff and Staff Blackout dates */
  ReadRsvStaff = 'ReadRsvStaff',
  /** Allows read of Training Asset */
  ReadRsvTrainingAsset = 'ReadRsvTrainingAsset',
  /** Allows read of WaitingList */
  ReadRsvWaitingList = 'ReadRsvWaitingList',
  /** Grants permission to read Seller */
  ReadSeller = 'ReadSeller',
  /** Grants permission to read PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  ReadSettings = 'ReadSettings',
  /** Grants permission to read ShippingMethod */
  ReadShippingMethod = 'ReadShippingMethod',
  /** Grants permission to read StockLocation */
  ReadStockLocation = 'ReadStockLocation',
  /** Grants permission to read System */
  ReadSystem = 'ReadSystem',
  /** Grants permission to read Tag */
  ReadTag = 'ReadTag',
  /** Grants permission to read TaxCategory */
  ReadTaxCategory = 'ReadTaxCategory',
  /** Grants permission to read TaxRate */
  ReadTaxRate = 'ReadTaxRate',
  /** Grants permission to read Zone */
  ReadZone = 'ReadZone',
  /** Rejects a participant on the waiting list */
  RejectWaitingListParticipant = 'RejectWaitingListParticipant',
  /** Remove member status from a customer */
  RemoveMember = 'RemoveMember',
  /** Removes an Operations Manager.  */
  RemoveOperationsManager = 'RemoveOperationsManager',
  /** Allows administrators to Produce Reports */
  Reporting = 'Reporting',
  /** Sends payment request email to customer */
  SendPaymentEmail = 'SendPaymentEmail',
  /** SuperAdmin has unrestricted access to all operations */
  SuperAdmin = 'SuperAdmin',
  /** Grants permission to update Administrator */
  UpdateAdministrator = 'UpdateAdministrator',
  /** Allows update of Agreement */
  UpdateAgreement = 'UpdateAgreement',
  /** Grants permission to update Asset */
  UpdateAsset = 'UpdateAsset',
  /** Grants permission to update Products, Facets, Assets, Collections */
  UpdateCatalog = 'UpdateCatalog',
  /** Grants permission to update Channel */
  UpdateChannel = 'UpdateChannel',
  /** Grants permission to update Collection */
  UpdateCollection = 'UpdateCollection',
  /** Grants permission to update Country */
  UpdateCountry = 'UpdateCountry',
  /** Grants permission to update Customer */
  UpdateCustomer = 'UpdateCustomer',
  /** Grants permission to update CustomerGroup */
  UpdateCustomerGroup = 'UpdateCustomerGroup',
  /** Grants permission to update Facet */
  UpdateFacet = 'UpdateFacet',
  /** Grants permission to update GlobalSettings */
  UpdateGlobalSettings = 'UpdateGlobalSettings',
  /** Grants permission to update Order */
  UpdateOrder = 'UpdateOrder',
  /** Grants permission to update PaymentMethod */
  UpdatePaymentMethod = 'UpdatePaymentMethod',
  /** Grants permission to update Product */
  UpdateProduct = 'UpdateProduct',
  /** Grants permission to update Promotion */
  UpdatePromotion = 'UpdatePromotion',
  /** Allows update of Blackout Dates */
  UpdateRsvBlackoutDate = 'UpdateRsvBlackoutDate',
  /** Allows update of Clazzes, Class Instances, Class Blackout dates, etc */
  UpdateRsvClazz = 'UpdateRsvClazz',
  /** Allows update of Instructors and Instructor Blackout dates */
  UpdateRsvInstructor = 'UpdateRsvInstructor',
  /** Allows the update of location based entities: Locations, ClassRooms, Location Blackout dates, Training Assets, etc */
  UpdateRsvLocation = 'UpdateRsvLocation',
  /** Allows update of Martial Art */
  UpdateRsvMartialArt = 'UpdateRsvMartialArt',
  /** Allows update of Membership */
  UpdateRsvMembership = 'UpdateRsvMembership',
  /** Allows update of Participant / Onboarding */
  UpdateRsvOnboarding = 'UpdateRsvOnboarding',
  /** Allows update of Participants */
  UpdateRsvParticipant = 'UpdateRsvParticipant',
  /** Allows update of Pickup Code */
  UpdateRsvPickupCode = 'UpdateRsvPickupCode',
  /** Allows update of Rank */
  UpdateRsvRank = 'UpdateRsvRank',
  /** Allows update of Schedules */
  UpdateRsvSchedule = 'UpdateRsvSchedule',
  /** Allows update of Staff and Staff Blackout dates */
  UpdateRsvStaff = 'UpdateRsvStaff',
  /** Allows update of Training Asset */
  UpdateRsvTrainingAsset = 'UpdateRsvTrainingAsset',
  /** Allows update of WaitingList */
  UpdateRsvWaitingList = 'UpdateRsvWaitingList',
  /** Grants permission to update Seller */
  UpdateSeller = 'UpdateSeller',
  /** Grants permission to update PaymentMethods, ShippingMethods, TaxCategories, TaxRates, Zones, Countries, System & GlobalSettings */
  UpdateSettings = 'UpdateSettings',
  /** Grants permission to update ShippingMethod */
  UpdateShippingMethod = 'UpdateShippingMethod',
  /** Grants permission to update StockLocation */
  UpdateStockLocation = 'UpdateStockLocation',
  /** Grants permission to update System */
  UpdateSystem = 'UpdateSystem',
  /** Grants permission to update Tag */
  UpdateTag = 'UpdateTag',
  /** Grants permission to update TaxCategory */
  UpdateTaxCategory = 'UpdateTaxCategory',
  /** Grants permission to update TaxRate */
  UpdateTaxRate = 'UpdateTaxRate',
  /** Grants permission to update Zone */
  UpdateZone = 'UpdateZone'
}

/** The price range where the result has more than one price */
export type PriceRange = {
  __typename?: 'PriceRange';
  max: Scalars['Money'];
  min: Scalars['Money'];
};

export type Product = Node & {
  __typename?: 'Product';
  assets: Array<Asset>;
  collections: Array<Collection>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<ProductCustomFields>;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  facetValues: Array<FacetValue>;
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  optionGroups: Array<ProductOptionGroup>;
  reviews: ProductReviewList;
  reviewsHistogram: Array<ProductReviewHistogramItem>;
  slug: Scalars['String'];
  translations: Array<ProductTranslation>;
  updatedAt: Scalars['DateTime'];
  /** Returns a paginated, sortable, filterable list of ProductVariants */
  variantList: ProductVariantList;
  /** Returns all ProductVariants */
  variants: Array<ProductVariant>;
};


export type ProductReviewsArgs = {
  options?: InputMaybe<ProductReviewListOptions>;
};


export type ProductVariantListArgs = {
  options?: InputMaybe<ProductVariantListOptions>;
};

export type ProductCustomFields = {
  __typename?: 'ProductCustomFields';
  agreement?: Maybe<Agreement>;
  allowChangeContractDate?: Maybe<Scalars['Boolean']>;
  allowParticipationStartDate?: Maybe<Scalars['Boolean']>;
  chargeDateOffset?: Maybe<Scalars['Int']>;
  downpayment?: Maybe<Scalars['Int']>;
  downpaymentIsRegistration?: Maybe<Scalars['Boolean']>;
  featuredReview?: Maybe<ProductReview>;
  gracePeriodInDays?: Maybe<Scalars['Int']>;
  martialArt?: Maybe<RsvMartialArt>;
  percentComplete?: Maybe<Scalars['Float']>;
  pricingAdvanceStartDate?: Maybe<Scalars['Boolean']>;
  pricingEndDate?: Maybe<Scalars['DateTime']>;
  pricingInterval?: Maybe<Scalars['String']>;
  pricingLowest?: Maybe<Scalars['Int']>;
  pricingStartDate?: Maybe<Scalars['DateTime']>;
  pricingUseCalculated?: Maybe<Scalars['Boolean']>;
  reviewCount?: Maybe<Scalars['Float']>;
  reviewRating?: Maybe<Scalars['Float']>;
  survey?: Maybe<Scalars['String']>;
  surveyCount?: Maybe<Scalars['Float']>;
  surveyRequired?: Maybe<Scalars['Boolean']>;
};

export type ProductFilterParameter = {
  _and?: InputMaybe<Array<ProductFilterParameter>>;
  _or?: InputMaybe<Array<ProductFilterParameter>>;
  allowChangeContractDate?: InputMaybe<BooleanOperators>;
  allowParticipationStartDate?: InputMaybe<BooleanOperators>;
  chargeDateOffset?: InputMaybe<NumberOperators>;
  createdAt?: InputMaybe<DateOperators>;
  description?: InputMaybe<StringOperators>;
  downpayment?: InputMaybe<NumberOperators>;
  downpaymentIsRegistration?: InputMaybe<BooleanOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  gracePeriodInDays?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  percentComplete?: InputMaybe<NumberOperators>;
  pricingAdvanceStartDate?: InputMaybe<BooleanOperators>;
  pricingEndDate?: InputMaybe<DateOperators>;
  pricingInterval?: InputMaybe<StringOperators>;
  pricingLowest?: InputMaybe<NumberOperators>;
  pricingStartDate?: InputMaybe<DateOperators>;
  pricingUseCalculated?: InputMaybe<BooleanOperators>;
  reviewCount?: InputMaybe<NumberOperators>;
  reviewRating?: InputMaybe<NumberOperators>;
  slug?: InputMaybe<StringOperators>;
  survey?: InputMaybe<StringOperators>;
  surveyCount?: InputMaybe<NumberOperators>;
  surveyRequired?: InputMaybe<BooleanOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ProductList = PaginatedList & {
  __typename?: 'ProductList';
  items: Array<Product>;
  totalItems: Scalars['Int'];
};

export type ProductListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProductFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProductSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ProductOption = Node & {
  __typename?: 'ProductOption';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  group: ProductOptionGroup;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  translations: Array<ProductOptionTranslation>;
  updatedAt: Scalars['DateTime'];
};

export type ProductOptionGroup = Node & {
  __typename?: 'ProductOptionGroup';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  options: Array<ProductOption>;
  translations: Array<ProductOptionGroupTranslation>;
  updatedAt: Scalars['DateTime'];
};

export type ProductOptionGroupTranslation = {
  __typename?: 'ProductOptionGroupTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductOptionTranslation = {
  __typename?: 'ProductOptionTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductReview = Node & {
  __typename?: 'ProductReview';
  authorLocation?: Maybe<Scalars['String']>;
  authorName: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  downvotes: Scalars['Int'];
  id: Scalars['ID'];
  product: Product;
  productVariant?: Maybe<ProductVariant>;
  rating: Scalars['Float'];
  response?: Maybe<Scalars['String']>;
  responseCreatedAt?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
  summary: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  upvotes: Scalars['Int'];
};

export type ProductReviewFilterParameter = {
  _and?: InputMaybe<Array<ProductReviewFilterParameter>>;
  _or?: InputMaybe<Array<ProductReviewFilterParameter>>;
  authorLocation?: InputMaybe<StringOperators>;
  authorName?: InputMaybe<StringOperators>;
  body?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  downvotes?: InputMaybe<NumberOperators>;
  id?: InputMaybe<IdOperators>;
  rating?: InputMaybe<NumberOperators>;
  response?: InputMaybe<StringOperators>;
  responseCreatedAt?: InputMaybe<DateOperators>;
  state?: InputMaybe<StringOperators>;
  summary?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
  upvotes?: InputMaybe<NumberOperators>;
};

export type ProductReviewHistogramItem = {
  __typename?: 'ProductReviewHistogramItem';
  bin: Scalars['Int'];
  frequency: Scalars['Int'];
};

export type ProductReviewList = PaginatedList & {
  __typename?: 'ProductReviewList';
  items: Array<ProductReview>;
  totalItems: Scalars['Int'];
};

export type ProductReviewListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProductReviewFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProductReviewSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ProductReviewSortParameter = {
  authorLocation?: InputMaybe<SortOrder>;
  authorName?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  downvotes?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  response?: InputMaybe<SortOrder>;
  responseCreatedAt?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  summary?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  upvotes?: InputMaybe<SortOrder>;
};

export type ProductSortParameter = {
  agreement?: InputMaybe<SortOrder>;
  allowChangeContractDate?: InputMaybe<SortOrder>;
  allowParticipationStartDate?: InputMaybe<SortOrder>;
  chargeDateOffset?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  downpayment?: InputMaybe<SortOrder>;
  downpaymentIsRegistration?: InputMaybe<SortOrder>;
  featuredReview?: InputMaybe<SortOrder>;
  gracePeriodInDays?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  martialArt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  percentComplete?: InputMaybe<SortOrder>;
  pricingAdvanceStartDate?: InputMaybe<SortOrder>;
  pricingEndDate?: InputMaybe<SortOrder>;
  pricingInterval?: InputMaybe<SortOrder>;
  pricingLowest?: InputMaybe<SortOrder>;
  pricingStartDate?: InputMaybe<SortOrder>;
  pricingUseCalculated?: InputMaybe<SortOrder>;
  reviewCount?: InputMaybe<SortOrder>;
  reviewRating?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  survey?: InputMaybe<SortOrder>;
  surveyCount?: InputMaybe<SortOrder>;
  surveyRequired?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductTranslation = {
  __typename?: 'ProductTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProductVariant = Node & {
  __typename?: 'ProductVariant';
  assets: Array<Asset>;
  createdAt: Scalars['DateTime'];
  currencyCode: CurrencyCode;
  customFields?: Maybe<ProductVariantCustomFields>;
  facetValues: Array<FacetValue>;
  featuredAsset?: Maybe<Asset>;
  id: Scalars['ID'];
  isClass: Scalars['Boolean'];
  isSubscription: Scalars['Boolean'];
  isTrial: Scalars['Boolean'];
  languageCode: LanguageCode;
  maxTotalParticipants?: Maybe<Array<MaxTotalResultType>>;
  name: Scalars['String'];
  options: Array<ProductOption>;
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
  product: Product;
  productId: Scalars['ID'];
  sku: Scalars['String'];
  stockLevel: Scalars['String'];
  subscriptionDetails?: Maybe<SubscriptionDetails>;
  taxCategory: TaxCategory;
  taxRateApplied: TaxRate;
  translations: Array<ProductVariantTranslation>;
  updatedAt: Scalars['DateTime'];
};


export type ProductVariantSubscriptionDetailsArgs = {
  input?: InputMaybe<SubscriptionConfiguration>;
};

export type ProductVariantCustomFields = {
  __typename?: 'ProductVariantCustomFields';
  agreement?: Maybe<Agreement>;
  autoRenew?: Maybe<Scalars['Boolean']>;
  originalPrice?: Maybe<Scalars['Int']>;
  packageDescription?: Maybe<Scalars['String']>;
  showDiscountPercentage?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  strategy?: Maybe<Scalars['String']>;
  strategyData?: Maybe<Scalars['String']>;
};

export type ProductVariantFilterParameter = {
  _and?: InputMaybe<Array<ProductVariantFilterParameter>>;
  _or?: InputMaybe<Array<ProductVariantFilterParameter>>;
  autoRenew?: InputMaybe<BooleanOperators>;
  createdAt?: InputMaybe<DateOperators>;
  currencyCode?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  isClass?: InputMaybe<BooleanOperators>;
  isSubscription?: InputMaybe<BooleanOperators>;
  isTrial?: InputMaybe<BooleanOperators>;
  languageCode?: InputMaybe<StringOperators>;
  name?: InputMaybe<StringOperators>;
  originalPrice?: InputMaybe<NumberOperators>;
  packageDescription?: InputMaybe<StringOperators>;
  price?: InputMaybe<NumberOperators>;
  priceWithTax?: InputMaybe<NumberOperators>;
  productId?: InputMaybe<IdOperators>;
  showDiscountPercentage?: InputMaybe<BooleanOperators>;
  sku?: InputMaybe<StringOperators>;
  startDate?: InputMaybe<DateOperators>;
  stockLevel?: InputMaybe<StringOperators>;
  strategy?: InputMaybe<StringOperators>;
  strategyData?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type ProductVariantList = PaginatedList & {
  __typename?: 'ProductVariantList';
  items: Array<ProductVariant>;
  totalItems: Scalars['Int'];
};

export type ProductVariantListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<ProductVariantFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<ProductVariantSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type ProductVariantSortParameter = {
  agreement?: InputMaybe<SortOrder>;
  autoRenew?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  originalPrice?: InputMaybe<SortOrder>;
  packageDescription?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  priceWithTax?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  showDiscountPercentage?: InputMaybe<SortOrder>;
  sku?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  stockLevel?: InputMaybe<SortOrder>;
  strategy?: InputMaybe<SortOrder>;
  strategyData?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductVariantTranslation = {
  __typename?: 'ProductVariantTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Promotion = Node & {
  __typename?: 'Promotion';
  actions: Array<ConfigurableOperation>;
  conditions: Array<ConfigurableOperation>;
  couponCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  endsAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  perCustomerUsageLimit?: Maybe<Scalars['Int']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  translations: Array<PromotionTranslation>;
  updatedAt: Scalars['DateTime'];
  usageLimit?: Maybe<Scalars['Int']>;
};

export type PromotionList = PaginatedList & {
  __typename?: 'PromotionList';
  items: Array<Promotion>;
  totalItems: Scalars['Int'];
};

export type PromotionTranslation = {
  __typename?: 'PromotionTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Province = Node & Region & {
  __typename?: 'Province';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  parent?: Maybe<Region>;
  parentId?: Maybe<Scalars['ID']>;
  translations: Array<RegionTranslation>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProvinceList = PaginatedList & {
  __typename?: 'ProvinceList';
  items: Array<Province>;
  totalItems: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** The active Channel */
  activeChannel: Channel;
  /** The active Customer */
  activeCustomer?: Maybe<Customer>;
  /** Fetch the current logged in group member */
  activeCustomerManagedGroupMember?: Maybe<CustomerManagedGroupMember>;
  /**
   * The active Order. Will be `null` until an Order is created via `addItemToOrder`. Once an Order reaches the
   * state of `PaymentAuthorized` or `PaymentSettled`, then that Order is no longer considered "active" and this
   * query will once again return `null`.
   */
  activeOrder?: Maybe<Order>;
  agreement: Agreement;
  agreements: AgreementList;
  /** An array of supported Countries */
  availableCountries: Array<Country>;
  availablePromotions: Array<Promotion>;
  /** Returns a Collection either by its id or slug. If neither 'id' nor 'slug' is specified, an error will result. */
  collection?: Maybe<Collection>;
  /** A list of Collections available to the shop */
  collections: CollectionList;
  /** Returns a list of payment methods and their eligibility based on the current active Order */
  eligiblePaymentMethods: Array<PaymentMethodQuote>;
  /** Returns a list of eligible shipping methods based on the current active Order */
  eligibleShippingMethods: Array<ShippingMethodQuote>;
  /** Returns a Facet by its id */
  facet?: Maybe<Facet>;
  /** A list of Facets available to the shop */
  facets: FacetList;
  filteredGroupMembers: Array<FilteredCustomerManagedGroupMember>;
  generateBraintreeClientToken?: Maybe<Scalars['String']>;
  /** Returns information about the current authenticated User */
  me?: Maybe<CurrentUser>;
  myCustomerManagedGroup?: Maybe<CustomerManagedGroup>;
  /** Returns the possible next states that the activeOrder can transition to */
  nextOrderStates: Array<Scalars['String']>;
  /**
   * Returns an Order based on the id. Note that in the Shop API, only orders belonging to the
   * currently-authenticated User may be queried.
   */
  order?: Maybe<Order>;
  /**
   * Returns an Order based on the order `code`. For guest Orders (i.e. Orders placed by non-authenticated Customers)
   * this query will only return the Order within 2 hours of the Order being placed. This allows an Order confirmation
   * screen to be shown immediately after completion of a guest checkout, yet prevents security risks of allowing
   * general anonymous access to Order data.
   */
  orderByCode?: Maybe<Order>;
  orderPayment: Payment;
  orderPayments: PaymentList;
  /** Fetch placed orders for each member of the group */
  ordersForMyCustomerManagedGroup: OrderList;
  previewAcceptBlueSubscriptions: Array<AcceptBlueSubscription>;
  previewAcceptBlueSubscriptionsForProduct: Array<AcceptBlueSubscription>;
  previewStripeSubscriptions: Array<StripeSubscription>;
  previewStripeSubscriptionsForProduct: Array<StripeSubscription>;
  previewSubscriptions: Array<Subscription>;
  /** Get a Product either by id or slug. If neither 'id' nor 'slug' is specified, an error will result. */
  product?: Maybe<Product>;
  /** Get a list of Products */
  products: ProductList;
  rsv_address: Address;
  rsv_blackoutDate: RsvBlackoutDate;
  rsv_channelBlackoutDates: RsvBlackoutDateList;
  rsv_classGroup: RsvClassGroup;
  rsv_classGroupInstructor: RsvClassGroupInstructor;
  rsv_classGroupInstructors: RsvClassGroupInstructorList;
  rsv_classGroups: RsvClassGroupList;
  rsv_classInstance: RsvClassInstance;
  rsv_classInstanceInstructor: RsvClassInstanceInstructor;
  rsv_classInstanceInstructors: RsvClassInstanceInstructorList;
  rsv_classInstanceParticipant: RsvClassInstanceParticipant;
  rsv_classInstances: RsvClassInstanceList;
  rsv_classInstancesByAttendance: Array<Maybe<RsvClassInstance>>;
  rsv_classRoom: RsvClassRoom;
  rsv_classRooms: RsvClassRoomList;
  rsv_clazz: RsvClazz;
  rsv_clazzBlackoutDates: RsvBlackoutDateList;
  rsv_clazzParticipations: RsvParticipantList;
  rsv_clazzes: RsvClazzList;
  rsv_clazzesByDate: Array<RsvClassInstance>;
  rsv_clazzesByDateRange: Array<RsvClassInstance>;
  rsv_customerPickupCode?: Maybe<RsvPickupCode>;
  rsv_instructor: RsvStaff;
  rsv_instructorClassGroups: RsvClassGroupInstructorList;
  rsv_instructors: RsvStaffList;
  rsv_location: RsvLocation;
  rsv_locationBlackoutDates: RsvBlackoutDateList;
  rsv_locations: RsvLocationList;
  rsv_martialArt: RsvMartialArt;
  rsv_martialArts: RsvMartialArtList;
  rsv_membership: RsvMembership;
  rsv_membershipPayments: RsvMembershipPaymentList;
  rsv_memberships: RsvMembershipList;
  rsv_participant: RsvParticipant;
  rsv_pickupCode: RsvPickupCode;
  rsv_productRelatedClassGroups: Array<RsvClassGroup>;
  rsv_rank: RsvRank;
  rsv_ranks: RsvRankList;
  rsv_searchInstructors: Array<RsvStaff>;
  rsv_searchStaff: Array<RsvStaff>;
  rsv_staff: RsvStaff;
  rsv_staffBlackoutDates: RsvBlackoutDateList;
  rsv_staffList: RsvStaffList;
  rsv_stripe: RsvStripe;
  rsv_stripes: RsvStripeList;
  rsv_timezones: Array<Scalars['String']>;
  rsv_trainingAsset: RsvTrainingAsset;
  rsv_trainingAssets: RsvTrainingAssetList;
  rsv_unassignedClazzes: Array<RsvClazz>;
  rsv_upcomingClazzes: Array<RsvClassInstanceParticipant>;
  rsv_verifyPickupCode: RsvPickupCodeCustomerResult;
  rsv_waitingListParticipants: RsvWaitingListParticipantList;
  rsv_waitingListStatus: RsvWaitingListParticipant;
  /** Search Products based on the criteria set by the `SearchInput` */
  search: SearchResponse;
  settings: DefaultSettings;
  subscriptionPayment: SubscriptionPayment;
  subscriptionPayments: SubscriptionPaymentList;
  surcharge: SurchargeConfig;
  surchargePreview: Scalars['Float'];
  variantAgreement?: Maybe<Agreement>;
};


export type QueryAgreementArgs = {
  id: Scalars['ID'];
};


export type QueryAgreementsArgs = {
  options?: InputMaybe<AgreementListOptions>;
};


export type QueryCollectionArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryCollectionsArgs = {
  options?: InputMaybe<CollectionListOptions>;
};


export type QueryFacetArgs = {
  id: Scalars['ID'];
};


export type QueryFacetsArgs = {
  options?: InputMaybe<FacetListOptions>;
};


export type QueryFilteredGroupMembersArgs = {
  productVariantId: Scalars['ID'];
  upgradeMode?: InputMaybe<Scalars['Boolean']>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderByCodeArgs = {
  code: Scalars['String'];
};


export type QueryOrderPaymentArgs = {
  paymentId: Scalars['ID'];
};


export type QueryOrderPaymentsArgs = {
  options?: InputMaybe<PaymentListOptions>;
};


export type QueryOrdersForMyCustomerManagedGroupArgs = {
  options?: InputMaybe<OrderListOptions>;
};


export type QueryPreviewAcceptBlueSubscriptionsArgs = {
  customInputs?: InputMaybe<Scalars['JSON']>;
  productVariantId: Scalars['ID'];
};


export type QueryPreviewAcceptBlueSubscriptionsForProductArgs = {
  customInputs?: InputMaybe<Scalars['JSON']>;
  productId: Scalars['ID'];
};


export type QueryPreviewStripeSubscriptionsArgs = {
  customInputs?: InputMaybe<Scalars['JSON']>;
  productVariantId: Scalars['ID'];
};


export type QueryPreviewStripeSubscriptionsForProductArgs = {
  customInputs?: InputMaybe<Scalars['JSON']>;
  productId: Scalars['ID'];
};


export type QueryPreviewSubscriptionsArgs = {
  customInputs?: InputMaybe<SubscriptionConfiguration>;
  productVariantId: Scalars['ID'];
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryProductsArgs = {
  options?: InputMaybe<ProductListOptions>;
};


export type QueryRsv_AddressArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_BlackoutDateArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_ChannelBlackoutDatesArgs = {
  channelId: Scalars['ID'];
  options?: InputMaybe<RsvBlackoutDateListOptions>;
};


export type QueryRsv_ClassGroupArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_ClassGroupInstructorArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_ClassGroupInstructorsArgs = {
  classGroupId?: InputMaybe<Scalars['ID']>;
  clazzId: Scalars['ID'];
  options?: InputMaybe<RsvClassGroupInstructorListOptions>;
  timeslotId?: InputMaybe<Scalars['ID']>;
};


export type QueryRsv_ClassGroupsArgs = {
  options?: InputMaybe<RsvClassGroupListOptions>;
};


export type QueryRsv_ClassInstanceArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_ClassInstanceInstructorArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_ClassInstanceInstructorsArgs = {
  classInstanceId: Scalars['ID'];
  options?: InputMaybe<RsvClassInstanceInstructorListOptions>;
};


export type QueryRsv_ClassInstanceParticipantArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_ClassInstancesArgs = {
  classGroupId: Scalars['ID'];
  options?: InputMaybe<RsvClassInstanceListOptions>;
};


export type QueryRsv_ClassInstancesByAttendanceArgs = {
  classGroupId: Scalars['ID'];
  customerId: Scalars['ID'];
};


export type QueryRsv_ClassRoomArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_ClassRoomsArgs = {
  locationId: Scalars['ID'];
  options?: InputMaybe<RsvClassRoomListOptions>;
};


export type QueryRsv_ClazzArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_ClazzBlackoutDatesArgs = {
  clazzId: Scalars['ID'];
  options?: InputMaybe<RsvBlackoutDateListOptions>;
};


export type QueryRsv_ClazzParticipationsArgs = {
  options?: InputMaybe<RsvParticipantListOptions>;
};


export type QueryRsv_ClazzesArgs = {
  options?: InputMaybe<RsvClazzListOptions>;
};


export type QueryRsv_ClazzesByDateArgs = {
  classGroupId?: InputMaybe<Scalars['ID']>;
  clazzId?: InputMaybe<Scalars['ID']>;
  date: Scalars['Date'];
  instructorId?: InputMaybe<Scalars['ID']>;
  locationId?: InputMaybe<Scalars['ID']>;
};


export type QueryRsv_ClazzesByDateRangeArgs = {
  classGroupId?: InputMaybe<Scalars['ID']>;
  classRoomId?: InputMaybe<Scalars['ID']>;
  clazzId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  instructorId?: InputMaybe<Scalars['ID']>;
  locationId?: InputMaybe<Scalars['ID']>;
  startDate: Scalars['Date'];
};


export type QueryRsv_CustomerPickupCodeArgs = {
  customerId: Scalars['ID'];
};


export type QueryRsv_InstructorArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_InstructorClassGroupsArgs = {
  options?: InputMaybe<RsvClassGroupInstructorListOptions>;
  staffId: Scalars['ID'];
};


export type QueryRsv_InstructorsArgs = {
  options?: InputMaybe<RsvStaffListOptions>;
};


export type QueryRsv_LocationArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_LocationBlackoutDatesArgs = {
  locationId: Scalars['ID'];
  options?: InputMaybe<RsvBlackoutDateListOptions>;
};


export type QueryRsv_LocationsArgs = {
  options?: InputMaybe<RsvLocationListOptions>;
};


export type QueryRsv_MartialArtArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_MartialArtsArgs = {
  options?: InputMaybe<RsvMartialArtListOptions>;
};


export type QueryRsv_MembershipArgs = {
  assignedCustomerId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Array<RsvMembershipStatus>>;
};


export type QueryRsv_MembershipPaymentsArgs = {
  options?: InputMaybe<RsvMembershipPaymentListOptions>;
};


export type QueryRsv_MembershipsArgs = {
  options?: InputMaybe<RsvMembershipListOptions>;
};


export type QueryRsv_ParticipantArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_PickupCodeArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_ProductRelatedClassGroupsArgs = {
  classGroupId?: InputMaybe<Scalars['ID']>;
  productId?: InputMaybe<Scalars['ID']>;
};


export type QueryRsv_RankArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_RanksArgs = {
  martialArtId?: InputMaybe<Scalars['ID']>;
  options?: InputMaybe<RsvRankListOptions>;
};


export type QueryRsv_SearchInstructorsArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type QueryRsv_SearchStaffArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type QueryRsv_StaffArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_StaffBlackoutDatesArgs = {
  options?: InputMaybe<RsvBlackoutDateListOptions>;
  staffId: Scalars['ID'];
};


export type QueryRsv_StaffListArgs = {
  options?: InputMaybe<RsvStaffListOptions>;
};


export type QueryRsv_StripeArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_StripesArgs = {
  options?: InputMaybe<RsvStripeListOptions>;
  rankId?: InputMaybe<Scalars['ID']>;
};


export type QueryRsv_TrainingAssetArgs = {
  id: Scalars['ID'];
};


export type QueryRsv_TrainingAssetsArgs = {
  clazzId?: InputMaybe<Scalars['ID']>;
  options?: InputMaybe<RsvTrainingAssetListOptions>;
};


export type QueryRsv_UpcomingClazzesArgs = {
  classGroupId?: InputMaybe<Scalars['ID']>;
  customerId: Scalars['ID'];
};


export type QueryRsv_VerifyPickupCodeArgs = {
  token: Scalars['String'];
};


export type QueryRsv_WaitingListParticipantsArgs = {
  clazzId: Scalars['ID'];
  option?: InputMaybe<RsvWaitingListParticipantListOptions>;
};


export type QueryRsv_WaitingListStatusArgs = {
  clazzId: Scalars['ID'];
};


export type QuerySearchArgs = {
  input: SearchInput;
};


export type QuerySubscriptionPaymentArgs = {
  paymentId: Scalars['ID'];
};


export type QuerySubscriptionPaymentsArgs = {
  options?: InputMaybe<SubscriptionPaymentListOptions>;
};


export type QuerySurchargeArgs = {
  paymentMethodCode: Scalars['String'];
};


export type QuerySurchargePreviewArgs = {
  orderId: Scalars['ID'];
  paymentMethodCode: Scalars['String'];
};


export type QueryVariantAgreementArgs = {
  variantId: Scalars['ID'];
};

export type RefreshCustomerVerificationResult = NativeAuthStrategyError | Success;

export type Refund = Node & {
  __typename?: 'Refund';
  adjustment: Scalars['Money'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  items: Scalars['Money'];
  lines: Array<RefundLine>;
  metadata?: Maybe<Scalars['JSON']>;
  method?: Maybe<Scalars['String']>;
  paymentId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  shipping: Scalars['Money'];
  state: Scalars['String'];
  total: Scalars['Money'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type RefundLine = {
  __typename?: 'RefundLine';
  orderLine: OrderLine;
  orderLineId: Scalars['ID'];
  quantity: Scalars['Int'];
  refund: Refund;
  refundId: Scalars['ID'];
};

export type Region = {
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  parent?: Maybe<Region>;
  parentId?: Maybe<Scalars['ID']>;
  translations: Array<RegionTranslation>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RegionTranslation = {
  __typename?: 'RegionTranslation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RegisterCustomerAccountResult = MissingPasswordError | NativeAuthStrategyError | PasswordValidationError | Success;

export type RegisterCustomerCustomFieldsInput = {
  acceptBlueCustomerId?: InputMaybe<Scalars['Int']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  customerRankId?: InputMaybe<Scalars['ID']>;
  guardianId?: InputMaybe<Scalars['ID']>;
  healthProfileResult?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['ID']>;
  phoneticName?: InputMaybe<Scalars['String']>;
  trialCompleted?: InputMaybe<Scalars['DateTime']>;
};

export type RegisterCustomerInput = {
  customFields?: InputMaybe<RegisterCustomerCustomFieldsInput>;
  emailAddress: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type RelationCustomFieldConfig = CustomField & {
  __typename?: 'RelationCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  entity: Scalars['String'];
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  scalarFields: Array<Scalars['String']>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type RemoveOrderItemsResult = Order | OrderModificationError;

export type RequestPasswordResetResult = NativeAuthStrategyError | Success;

export type RequestUpdateCustomerEmailAddressResult = EmailAddressConflictError | InvalidCredentialsError | NativeAuthStrategyError | Success;

export type ResetPasswordResult = CurrentUser | NativeAuthStrategyError | NotVerifiedError | PasswordResetTokenExpiredError | PasswordResetTokenInvalidError | PasswordValidationError;

export type Role = Node & {
  __typename?: 'Role';
  channels: Array<Channel>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  permissions: Array<Permission>;
  updatedAt: Scalars['DateTime'];
};

export type RoleList = PaginatedList & {
  __typename?: 'RoleList';
  items: Array<Role>;
  totalItems: Scalars['Int'];
};

export enum RsvAttendance {
  Cancelled = 'CANCELLED',
  NoShow = 'NO_SHOW',
  Present = 'PRESENT',
  Unknown = 'UNKNOWN'
}

export type RsvBlackoutDate = Node & {
  __typename?: 'RsvBlackoutDate';
  createdAt: Scalars['DateTime'];
  desc?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  updatedAt: Scalars['DateTime'];
};

export type RsvBlackoutDateFilterParameter = {
  _and?: InputMaybe<Array<RsvBlackoutDateFilterParameter>>;
  _or?: InputMaybe<Array<RsvBlackoutDateFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  desc?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvBlackoutDateList = PaginatedList & {
  __typename?: 'RsvBlackoutDateList';
  items: Array<RsvBlackoutDate>;
  totalItems: Scalars['Int'];
};

export type RsvBlackoutDateListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvBlackoutDateFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvBlackoutDateSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvBlackoutDateSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvCheckinParticipantMutationResult = RsvClassInstanceParticipant | ValidationError;

export type RsvClassGroup = Node & {
  __typename?: 'RsvClassGroup';
  allowOverflow: Scalars['Boolean'];
  classInstances: Array<RsvClassInstance>;
  clazz: RsvClazz;
  clazzId: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  instructors: Array<RsvClassGroupInstructor>;
  label: Scalars['String'];
  maxParticipants?: Maybe<Scalars['Int']>;
  minParticipants?: Maybe<Scalars['Int']>;
  participantCount: Scalars['Int'];
  participants: Array<RsvParticipant>;
  timeslots: Array<RsvTimeslot>;
  updatedAt: Scalars['DateTime'];
};

export type RsvClassGroupFilterParameter = {
  _and?: InputMaybe<Array<RsvClassGroupFilterParameter>>;
  _or?: InputMaybe<Array<RsvClassGroupFilterParameter>>;
  allowOverflow?: InputMaybe<BooleanOperators>;
  clazzId?: InputMaybe<IdOperators>;
  color?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  maxParticipants?: InputMaybe<NumberOperators>;
  minParticipants?: InputMaybe<NumberOperators>;
  participantCount?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvClassGroupInstructor = Node & {
  __typename?: 'RsvClassGroupInstructor';
  classGroup: RsvClassGroup;
  classGroupId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  instructor: RsvStaff;
  instructorId: Scalars['ID'];
  timeslot?: Maybe<RsvTimeslot>;
  timeslotId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
};

export type RsvClassGroupInstructorFilterParameter = {
  _and?: InputMaybe<Array<RsvClassGroupInstructorFilterParameter>>;
  _or?: InputMaybe<Array<RsvClassGroupInstructorFilterParameter>>;
  classGroupId?: InputMaybe<IdOperators>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  instructorId?: InputMaybe<IdOperators>;
  timeslotId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvClassGroupInstructorList = PaginatedList & {
  __typename?: 'RsvClassGroupInstructorList';
  items: Array<RsvClassGroupInstructor>;
  totalItems: Scalars['Int'];
};

export type RsvClassGroupInstructorListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvClassGroupInstructorFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvClassGroupInstructorSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvClassGroupInstructorSortParameter = {
  classGroupId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instructorId?: InputMaybe<SortOrder>;
  timeslotId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvClassGroupList = PaginatedList & {
  __typename?: 'RsvClassGroupList';
  items: Array<RsvClassGroup>;
  totalItems: Scalars['Int'];
};

export type RsvClassGroupListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvClassGroupFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvClassGroupSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvClassGroupSortParameter = {
  clazzId?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  maxParticipants?: InputMaybe<SortOrder>;
  minParticipants?: InputMaybe<SortOrder>;
  participantCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvClassInstance = Node & {
  __typename?: 'RsvClassInstance';
  cancelled: Scalars['Boolean'];
  classGroup: RsvClassGroup;
  classRoom?: Maybe<RsvClassRoom>;
  createdAt: Scalars['DateTime'];
  date: Scalars['Date'];
  endingAt: Scalars['DateTime'];
  id: Scalars['ID'];
  instructors: Array<RsvClassInstanceInstructor>;
  location: RsvLocation;
  participantCount?: Maybe<Scalars['Int']>;
  participants: Array<RsvClassInstanceParticipant>;
  startingAt: Scalars['DateTime'];
  time: Scalars['String'];
  timeslot: RsvTimeslot;
  updatedAt: Scalars['DateTime'];
};

export type RsvClassInstanceFilterParameter = {
  _and?: InputMaybe<Array<RsvClassInstanceFilterParameter>>;
  _or?: InputMaybe<Array<RsvClassInstanceFilterParameter>>;
  cancelled?: InputMaybe<BooleanOperators>;
  createdAt?: InputMaybe<DateOperators>;
  endingAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  participantCount?: InputMaybe<NumberOperators>;
  startingAt?: InputMaybe<DateOperators>;
  time?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvClassInstanceInstructor = Node & {
  __typename?: 'RsvClassInstanceInstructor';
  classInstance: RsvClassInstance;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  instructor: RsvStaff;
  updatedAt: Scalars['DateTime'];
};

export type RsvClassInstanceInstructorFilterParameter = {
  _and?: InputMaybe<Array<RsvClassInstanceInstructorFilterParameter>>;
  _or?: InputMaybe<Array<RsvClassInstanceInstructorFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvClassInstanceInstructorList = PaginatedList & {
  __typename?: 'RsvClassInstanceInstructorList';
  items: Array<RsvClassInstanceInstructor>;
  totalItems: Scalars['Int'];
};

export type RsvClassInstanceInstructorListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvClassInstanceInstructorFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvClassInstanceInstructorSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvClassInstanceInstructorSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvClassInstanceList = PaginatedList & {
  __typename?: 'RsvClassInstanceList';
  items: Array<RsvClassInstance>;
  totalItems: Scalars['Int'];
};

export type RsvClassInstanceListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvClassInstanceFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvClassInstanceSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvClassInstanceParticipant = Node & {
  __typename?: 'RsvClassInstanceParticipant';
  classInstance: RsvClassInstance;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  participant: RsvParticipant;
  status?: Maybe<RsvAttendance>;
  updatedAt: Scalars['DateTime'];
};

export type RsvClassInstanceSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  endingAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  participantCount?: InputMaybe<SortOrder>;
  startingAt?: InputMaybe<SortOrder>;
  time?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvClassInstructor = Node & {
  __typename?: 'RsvClassInstructor';
  classInstance?: Maybe<RsvClassInstance>;
  clazz: RsvClazz;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  instructor: RsvStaff;
  updatedAt: Scalars['DateTime'];
};

export type RsvClassIntanceParticipantInput = {
  status: RsvAttendance;
};

export type RsvClassProduct = Node & {
  __typename?: 'RsvClassProduct';
  createdAt: Scalars['DateTime'];
  desc?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  product: Product;
  updatedAt: Scalars['DateTime'];
};

export type RsvClassRoom = Node & {
  __typename?: 'RsvClassRoom';
  code: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  infiniteAvailability: Scalars['Boolean'];
  label: Scalars['String'];
  location: RsvLocation;
  maxParticipants: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type RsvClassRoomFilterParameter = {
  _and?: InputMaybe<Array<RsvClassRoomFilterParameter>>;
  _or?: InputMaybe<Array<RsvClassRoomFilterParameter>>;
  code?: InputMaybe<StringOperators>;
  color?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  infiniteAvailability?: InputMaybe<BooleanOperators>;
  label?: InputMaybe<StringOperators>;
  maxParticipants?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvClassRoomList = PaginatedList & {
  __typename?: 'RsvClassRoomList';
  items: Array<RsvClassRoom>;
  totalItems: Scalars['Int'];
};

export type RsvClassRoomListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvClassRoomFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvClassRoomSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvClassRoomSortParameter = {
  code?: InputMaybe<SortOrder>;
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  maxParticipants?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvClassTrainingAsset = Node & {
  __typename?: 'RsvClassTrainingAsset';
  clazz: RsvClazz;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  trainingAsset: RsvTrainingAsset;
  updatedAt: Scalars['DateTime'];
};

export type RsvClazz = Node & {
  __typename?: 'RsvClazz';
  allowMidClassSignup: Scalars['Boolean'];
  assets: Array<RsvClassTrainingAsset>;
  blackoutDates: Array<RsvBlackoutDate>;
  classRoom?: Maybe<RsvClassRoom>;
  createdAt: Scalars['DateTime'];
  duration: Scalars['Int'];
  enabled: Scalars['Boolean'];
  endDate?: Maybe<Scalars['Date']>;
  groups: Array<RsvClassGroup>;
  id: Scalars['ID'];
  label: Scalars['String'];
  location?: Maybe<RsvLocation>;
  maxParticipants?: Maybe<Scalars['Int']>;
  minParticipants?: Maybe<Scalars['Int']>;
  product: Product;
  productId: Scalars['ID'];
  requiredProducts: Array<RsvClassProduct>;
  startDate: Scalars['Date'];
  updatedAt: Scalars['DateTime'];
  waitingListParticipants: Array<RsvWaitingListParticipant>;
};

export type RsvClazzFilterParameter = {
  _and?: InputMaybe<Array<RsvClazzFilterParameter>>;
  _or?: InputMaybe<Array<RsvClazzFilterParameter>>;
  allowMidClassSignup?: InputMaybe<BooleanOperators>;
  createdAt?: InputMaybe<DateOperators>;
  duration?: InputMaybe<NumberOperators>;
  enabled?: InputMaybe<BooleanOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  maxParticipants?: InputMaybe<NumberOperators>;
  minParticipants?: InputMaybe<NumberOperators>;
  productId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvClazzList = PaginatedList & {
  __typename?: 'RsvClazzList';
  items: Array<RsvClazz>;
  totalItems: Scalars['Int'];
};

export type RsvClazzListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvClazzFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvClazzSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvClazzSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  maxParticipants?: InputMaybe<SortOrder>;
  minParticipants?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum RsvClazzStatus {
  Failed = 'FAILED',
  Graduate = 'GRADUATE',
  GraduationCandidate = 'GRADUATION_CANDIDATE',
  GraduationNoshow = 'GRADUATION_NOSHOW',
  Participant = 'PARTICIPANT',
  Removed = 'REMOVED'
}

export type RsvCreatePickupCodeMutationResult = RsvPickupCode | ValidationError;

export type RsvCustomerPickupCodeInput = {
  customerId: Scalars['ID'];
};

export type RsvCustomerRank = Node & {
  __typename?: 'RsvCustomerRank';
  id: Scalars['ID'];
  rank?: Maybe<RsvRank>;
  stripes?: Maybe<Array<RsvStripe>>;
};

export enum RsvDow {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED'
}

export enum RsvEmployeeStatus {
  Contract = 'CONTRACT',
  Employee = 'EMPLOYEE',
  ZeroHours = 'ZERO_HOURS'
}

export type RsvLocation = Node & {
  __typename?: 'RsvLocation';
  address?: Maybe<Address>;
  blackoutDates: Array<RsvBlackoutDate>;
  classRooms: Array<RsvClassRoom>;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  label: Scalars['String'];
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RsvLocationFilterParameter = {
  _and?: InputMaybe<Array<RsvLocationFilterParameter>>;
  _or?: InputMaybe<Array<RsvLocationFilterParameter>>;
  color?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  timezone?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvLocationList = PaginatedList & {
  __typename?: 'RsvLocationList';
  items: Array<RsvLocation>;
  totalItems: Scalars['Int'];
};

export type RsvLocationListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvLocationFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvLocationSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvLocationSortParameter = {
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvMartialArt = Node & {
  __typename?: 'RsvMartialArt';
  createdAt: Scalars['DateTime'];
  desc?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Asset>;
  label: Scalars['String'];
  progressive?: Maybe<Scalars['Boolean']>;
  ranks: Array<RsvRank>;
  updatedAt: Scalars['DateTime'];
};

export type RsvMartialArtFilterParameter = {
  _and?: InputMaybe<Array<RsvMartialArtFilterParameter>>;
  _or?: InputMaybe<Array<RsvMartialArtFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  desc?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  progressive?: InputMaybe<BooleanOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvMartialArtList = PaginatedList & {
  __typename?: 'RsvMartialArtList';
  items: Array<RsvMartialArt>;
  totalItems: Scalars['Int'];
};

export type RsvMartialArtListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvMartialArtFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvMartialArtSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvMartialArtSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvMembership = Node & {
  __typename?: 'RsvMembership';
  contractOwner?: Maybe<Customer>;
  contractOwnerId?: Maybe<Scalars['ID']>;
  customer: Customer;
  customerId: Scalars['ID'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  orderLines: Array<OrderLine>;
  pausedUntilDate?: Maybe<Scalars['DateTime']>;
  payments: Array<SubscriptionPayment>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<RsvMembershipStatus>;
};

export type RsvMembershipFilter = {
  assignedCustomerId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<RsvMembershipStatus>;
};

export type RsvMembershipFilterParameter = {
  _and?: InputMaybe<Array<RsvMembershipFilterParameter>>;
  _or?: InputMaybe<Array<RsvMembershipFilterParameter>>;
  contractOwnerId?: InputMaybe<IdOperators>;
  customerId?: InputMaybe<IdOperators>;
  endDate?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  pausedUntilDate?: InputMaybe<DateOperators>;
  startDate?: InputMaybe<DateOperators>;
  status?: InputMaybe<StringOperators>;
};

export type RsvMembershipList = PaginatedList & {
  __typename?: 'RsvMembershipList';
  items: Array<RsvMembership>;
  totalItems: Scalars['Int'];
};

export type RsvMembershipListOptions = {
  filter?: InputMaybe<RsvMembershipFilter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvMembershipSortParameter>;
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvMembershipMutationResult = MembershipTransitionError | RsvMembership | ValidationError;

export type RsvMembershipPaymentFilterParameter = {
  _and?: InputMaybe<Array<InputMaybe<RsvMembershipPaymentFilterParameter>>>;
  _or?: InputMaybe<Array<InputMaybe<RsvMembershipPaymentFilterParameter>>>;
  amount?: InputMaybe<NumberOperators>;
  assignedCustomerId?: InputMaybe<IdOperators>;
  createdAt?: InputMaybe<DateOperators>;
  customerId?: InputMaybe<IdOperators>;
  id?: InputMaybe<IdOperators>;
  membershipStatus?: InputMaybe<StringOperators>;
  method?: InputMaybe<StringOperators>;
  state?: InputMaybe<StringOperators>;
  transactionId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvMembershipPaymentList = PaginatedList & {
  __typename?: 'RsvMembershipPaymentList';
  items: Array<SubscriptionPayment>;
  totalItems: Scalars['Int'];
};

export type RsvMembershipPaymentListOptions = {
  filter?: InputMaybe<RsvMembershipPaymentFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<RsvMembershipPaymentSortParameter>;
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvMembershipPaymentSortParameter = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvMembershipSortParameter = {
  contractOwnerId?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pausedUntilDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export enum RsvMembershipStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Enrolled = 'ENROLLED',
  Enrolling = 'ENROLLING',
  Expired = 'EXPIRED',
  Pastdue = 'PASTDUE',
  Paused = 'PAUSED',
  Producttransition = 'PRODUCTTRANSITION',
  Trial = 'TRIAL'
}

export type RsvNoteInput = {
  note: Scalars['String'];
  visibility: RsvVisibility;
};

export type RsvNoteMutationResult = HistoryEntry | ValidationError;

export type RsvParticipant = Node & {
  __typename?: 'RsvParticipant';
  classGroup: RsvClassGroup;
  classGroupId: Scalars['ID'];
  classStatus: RsvClazzStatus;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['ID'];
  dropoutDate?: Maybe<Scalars['DateTime']>;
  graduationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  notes: Array<HistoryEntry>;
  updatedAt: Scalars['DateTime'];
};

export type RsvParticipantFilterParameter = {
  _and?: InputMaybe<Array<RsvParticipantFilterParameter>>;
  _or?: InputMaybe<Array<RsvParticipantFilterParameter>>;
  classGroupId?: InputMaybe<IdOperators>;
  classStatus?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  customerId?: InputMaybe<IdOperators>;
  dropoutDate?: InputMaybe<DateOperators>;
  graduationDate?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvParticipantList = PaginatedList & {
  __typename?: 'RsvParticipantList';
  items: Array<RsvParticipant>;
  totalItems: Scalars['Int'];
};

export type RsvParticipantListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvParticipantFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvParticipantSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvParticipantSortParameter = {
  classGroupId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customerId?: InputMaybe<SortOrder>;
  dropoutDate?: InputMaybe<SortOrder>;
  graduationDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvPickupCode = Node & {
  __typename?: 'RsvPickupCode';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customer: Customer;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type RsvPickupCodeCustomerInput = {
  code: Scalars['String'];
};

export type RsvPickupCodeCustomerResult = FailedPickupCodeError | RsvPickupCode;

export type RsvPickupCodeInput = {
  code: Scalars['String'];
  customerId: Scalars['ID'];
};

export type RsvPickupCodeMutationResult = RsvPickupCode | ValidationError;

export type RsvRank = Node & {
  __typename?: 'RsvRank';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  desc?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Asset>;
  label: Scalars['String'];
  martialArt: RsvMartialArt;
  ordering: Scalars['Int'];
  stripes: Array<RsvStripe>;
  updatedAt: Scalars['DateTime'];
};

export type RsvRankFilterParameter = {
  _and?: InputMaybe<Array<RsvRankFilterParameter>>;
  _or?: InputMaybe<Array<RsvRankFilterParameter>>;
  color?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  desc?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  ordering?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvRankList = PaginatedList & {
  __typename?: 'RsvRankList';
  items: Array<RsvRank>;
  totalItems: Scalars['Int'];
};

export type RsvRankListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvRankFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvRankSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvRankSortParameter = {
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  ordering?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvSchedule = Node & {
  __typename?: 'RsvSchedule';
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  label: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  validFrom: Scalars['DateTime'];
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type RsvScheduleList = PaginatedList & {
  __typename?: 'RsvScheduleList';
  items: Array<RsvSchedule>;
  totalItems: Scalars['Int'];
};

export type RsvShopStaff = Node & RsvStaff & {
  __typename?: 'RsvShopStaff';
  available: Scalars['Boolean'];
  blackoutDates: Array<RsvBlackoutDate>;
  channels: Array<Channel>;
  classGroups: Array<RsvClassGroupInstructor>;
  classInstances: Array<RsvClassInstanceInstructor>;
  createdAt: Scalars['DateTime'];
  employeeStatus?: Maybe<RsvEmployeeStatus>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: RsvStaffUser;
};

export type RsvStaff = {
  available: Scalars['Boolean'];
  blackoutDates: Array<RsvBlackoutDate>;
  channels: Array<Channel>;
  classGroups: Array<RsvClassGroupInstructor>;
  classInstances: Array<RsvClassInstanceInstructor>;
  createdAt: Scalars['DateTime'];
  employeeStatus?: Maybe<RsvEmployeeStatus>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: RsvStaffUser;
};

export type RsvStaffFilterParameter = {
  _and?: InputMaybe<Array<RsvStaffFilterParameter>>;
  _or?: InputMaybe<Array<RsvStaffFilterParameter>>;
  available?: InputMaybe<BooleanOperators>;
  createdAt?: InputMaybe<DateOperators>;
  employeeStatus?: InputMaybe<StringOperators>;
  firstName?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  lastName?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvStaffList = PaginatedList & {
  __typename?: 'RsvStaffList';
  items: Array<RsvStaff>;
  totalItems: Scalars['Int'];
};

export type RsvStaffListOptions = {
  filter?: InputMaybe<RsvStaffFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<RsvStaffSortParameter>;
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvStaffSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  emailAddress?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RsvStaffUser = Node & {
  __typename?: 'RsvStaffUser';
  avatar?: Maybe<Asset>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  roles: Array<Role>;
};

export type RsvStripe = Node & {
  __typename?: 'RsvStripe';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  desc?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Asset>;
  label: Scalars['String'];
  rank?: Maybe<RsvRank>;
  rankNumber: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type RsvStripeFilterParameter = {
  _and?: InputMaybe<Array<RsvStripeFilterParameter>>;
  _or?: InputMaybe<Array<RsvStripeFilterParameter>>;
  color?: InputMaybe<StringOperators>;
  createdAt?: InputMaybe<DateOperators>;
  desc?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  rankNumber?: InputMaybe<NumberOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvStripeList = PaginatedList & {
  __typename?: 'RsvStripeList';
  items: Array<RsvStripe>;
  totalItems: Scalars['Int'];
};

export type RsvStripeListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvStripeFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvStripeSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvStripeSortParameter = {
  color?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  desc?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  rankNumber?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum RsvSubscriptionPaymentInterval {
  Month = 'MONTH',
  Pif = 'PIF',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type RsvTimeslot = Node & {
  __typename?: 'RsvTimeslot';
  classGroup: RsvClassGroup;
  classInstances: Array<RsvClassInstance>;
  classRoom?: Maybe<RsvClassRoom>;
  createdAt: Scalars['DateTime'];
  dow: RsvDow;
  duration: Scalars['Int'];
  id: Scalars['ID'];
  location: RsvLocation;
  participantCount: Scalars['Int'];
  time: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RsvTrainingAsset = Node & {
  __typename?: 'RsvTrainingAsset';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  label: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type RsvTrainingAssetFilterParameter = {
  _and?: InputMaybe<Array<RsvTrainingAssetFilterParameter>>;
  _or?: InputMaybe<Array<RsvTrainingAssetFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  label?: InputMaybe<StringOperators>;
  type?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvTrainingAssetList = PaginatedList & {
  __typename?: 'RsvTrainingAssetList';
  items: Array<RsvTrainingAsset>;
  totalItems: Scalars['Int'];
};

export type RsvTrainingAssetListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvTrainingAssetFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvTrainingAssetSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvTrainingAssetSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum RsvVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Staff = 'STAFF'
}

export type RsvWaitingListParticipant = Node & {
  __typename?: 'RsvWaitingListParticipant';
  clazz: RsvClazz;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  participant: RsvParticipant;
  status: RsvWaitingListStatus;
  updatedAt: Scalars['DateTime'];
};

export type RsvWaitingListParticipantFilterParameter = {
  _and?: InputMaybe<Array<RsvWaitingListParticipantFilterParameter>>;
  _or?: InputMaybe<Array<RsvWaitingListParticipantFilterParameter>>;
  createdAt?: InputMaybe<DateOperators>;
  id?: InputMaybe<IdOperators>;
  status?: InputMaybe<StringOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type RsvWaitingListParticipantList = PaginatedList & {
  __typename?: 'RsvWaitingListParticipantList';
  items: Array<RsvWaitingListParticipant>;
  totalItems: Scalars['Int'];
};

export type RsvWaitingListParticipantListOptions = {
  /** Allows the results to be filtered */
  filter?: InputMaybe<RsvWaitingListParticipantFilterParameter>;
  /** Specifies whether multiple top-level "filter" fields should be combined with a logical AND or OR operation. Defaults to AND. */
  filterOperator?: InputMaybe<LogicalOperator>;
  /** Skips the first n results, for use in pagination */
  skip?: InputMaybe<Scalars['Int']>;
  /** Specifies which properties to sort the results by */
  sort?: InputMaybe<RsvWaitingListParticipantSortParameter>;
  /** Takes n results, for use in pagination */
  take?: InputMaybe<Scalars['Int']>;
};

export type RsvWaitingListParticipantSortParameter = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum RsvWaitingListStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Waiting = 'WAITING'
}

export type SearchInput = {
  collectionId?: InputMaybe<Scalars['ID']>;
  collectionSlug?: InputMaybe<Scalars['String']>;
  facetValueFilters?: InputMaybe<Array<FacetValueFilterInput>>;
  groupByProduct?: InputMaybe<Scalars['Boolean']>;
  inStock?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SearchResultSortParameter>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export type SearchReindexResponse = {
  __typename?: 'SearchReindexResponse';
  success: Scalars['Boolean'];
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  collections: Array<CollectionResult>;
  facetValues: Array<FacetValueResult>;
  items: Array<SearchResult>;
  totalItems: Scalars['Int'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  /** An array of ids of the Collections in which this result appears */
  collectionIds: Array<Scalars['ID']>;
  currencyCode: CurrencyCode;
  description: Scalars['String'];
  facetIds: Array<Scalars['ID']>;
  facetValueIds: Array<Scalars['ID']>;
  inStock: Scalars['Boolean'];
  price: SearchResultPrice;
  priceWithTax: SearchResultPrice;
  product: Product;
  productAsset?: Maybe<SearchResultAsset>;
  productId: Scalars['ID'];
  productName: Scalars['String'];
  productVariantAsset?: Maybe<SearchResultAsset>;
  productVariantId: Scalars['ID'];
  productVariantName: Scalars['String'];
  /** A relevance score for the result. Differs between database implementations */
  score: Scalars['Float'];
  sku: Scalars['String'];
  slug: Scalars['String'];
};

export type SearchResultAsset = {
  __typename?: 'SearchResultAsset';
  focalPoint?: Maybe<Coordinate>;
  id: Scalars['ID'];
  preview: Scalars['String'];
};

/** The price of a search result product, either as a range or as a single price */
export type SearchResultPrice = PriceRange | SinglePrice;

export type SearchResultSortParameter = {
  name?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
};

export type Seller = Node & {
  __typename?: 'Seller';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<SellerCustomFields>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SellerCustomFields = {
  __typename?: 'SellerCustomFields';
  connectedUserId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type SendLoginEmailMutationResult = InvalidCredentialsError | MissingLoginStrategyError | Success | ValidationError;

export type SetCustomerForOrderResult = AlreadyLoggedInError | EmailAddressConflictError | GuestCheckoutError | NoActiveOrderError | Order;

export type SetOrderShippingMethodResult = IneligibleShippingMethodError | NoActiveOrderError | Order | OrderModificationError;

export type ShippingLine = {
  __typename?: 'ShippingLine';
  discountedPrice: Scalars['Money'];
  discountedPriceWithTax: Scalars['Money'];
  discounts: Array<Discount>;
  id: Scalars['ID'];
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
  shippingMethod: ShippingMethod;
};

export type ShippingMethod = Node & {
  __typename?: 'ShippingMethod';
  calculator: ConfigurableOperation;
  checker: ConfigurableOperation;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  description: Scalars['String'];
  fulfillmentHandlerCode: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  translations: Array<ShippingMethodTranslation>;
  updatedAt: Scalars['DateTime'];
};

export type ShippingMethodList = PaginatedList & {
  __typename?: 'ShippingMethodList';
  items: Array<ShippingMethod>;
  totalItems: Scalars['Int'];
};

export type ShippingMethodQuote = {
  __typename?: 'ShippingMethodQuote';
  code: Scalars['String'];
  customFields?: Maybe<Scalars['JSON']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  /** Any optional metadata returned by the ShippingCalculator in the ShippingCalculationResult */
  metadata?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
};

export type ShippingMethodTranslation = {
  __typename?: 'ShippingMethodTranslation';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  languageCode: LanguageCode;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** The price value where the result has a single price */
export type SinglePrice = {
  __typename?: 'SinglePrice';
  value: Scalars['Money'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringCustomFieldConfig = CustomField & {
  __typename?: 'StringCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  length?: Maybe<Scalars['Int']>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<StringFieldOption>>;
  pattern?: Maybe<Scalars['String']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type StringFieldOption = {
  __typename?: 'StringFieldOption';
  label?: Maybe<Array<LocalizedString>>;
  value: Scalars['String'];
};

/** Operators for filtering on a list of String fields */
export type StringListOperators = {
  inList: Scalars['String'];
};

/** Operators for filtering on a String field */
export type StringOperators = {
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  isNull?: InputMaybe<Scalars['Boolean']>;
  notContains?: InputMaybe<Scalars['String']>;
  notEq?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  regex?: InputMaybe<Scalars['String']>;
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  amountDueNow: Scalars['Int'];
  name: Scalars['String'];
  priceIncludesTax: Scalars['Boolean'];
  recurring: StripeSubscriptionRecurringPayment;
  variantId: Scalars['ID'];
};

export type StripeSubscriptionIntent = {
  __typename?: 'StripeSubscriptionIntent';
  clientSecret: Scalars['String'];
  intentType: StripeSubscriptionIntentType;
};

export enum StripeSubscriptionIntentType {
  PaymentIntent = 'PaymentIntent',
  SetupIntent = 'SetupIntent'
}

export enum StripeSubscriptionInterval {
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export type StripeSubscriptionRecurringPayment = {
  __typename?: 'StripeSubscriptionRecurringPayment';
  amount: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  interval: StripeSubscriptionInterval;
  intervalCount: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type SubmitProductReviewInput = {
  authorLocation?: InputMaybe<Scalars['String']>;
  authorName: Scalars['String'];
  body: Scalars['String'];
  customerId?: InputMaybe<Scalars['ID']>;
  productId: Scalars['ID'];
  rating: Scalars['Float'];
  summary: Scalars['String'];
  variantId?: InputMaybe<Scalars['ID']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  amountDueNow: Scalars['Int'];
  /** This ID might not be available yet when an order hasn't been placed yet */
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  priceIncludesTax: Scalars['Boolean'];
  recurring: SubscriptionRecurringPayment;
  transactions?: Maybe<Array<SubscriptionPayment>>;
  variantId: Scalars['ID'];
};

export type SubscriptionConfiguration = {
  downpayment?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  interval?: InputMaybe<SubscriptionInterval>;
  intervalCount?: InputMaybe<Scalars['Int']>;
  onetimeDiscount?: InputMaybe<Scalars['Int']>;
  recurringChargeDay?: InputMaybe<Scalars['DateTime']>;
  recurringDiscount?: InputMaybe<Scalars['Int']>;
  recurringRegistrationDiscount?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  surcharge?: InputMaybe<Scalars['Int']>;
  surchargeType?: InputMaybe<SurchargeType>;
};

export type SubscriptionDetails = {
  __typename?: 'SubscriptionDetails';
  allowChangeChargeDate: Scalars['Boolean'];
  allowChangeContractDate: Scalars['Boolean'];
  allowParticipationStartDate: Scalars['Boolean'];
  amount: Scalars['Int'];
  amountDueNow: Scalars['Int'];
  amountDueNowSurcharge: Scalars['Int'];
  autoRenew?: Maybe<Scalars['Boolean']>;
  billingInterval: SubscriptionInterval;
  billingIntervalCount: Scalars['Int'];
  chargeDateOffset: Scalars['Int'];
  contractStartDate: Scalars['DateTime'];
  cycleStartDate: Scalars['DateTime'];
  dailyRate: Scalars['Int'];
  discountPerInterval: Scalars['Int'];
  discountedDownpayment: Scalars['Int'];
  downpayment: Scalars['Int'];
  downpaymentIsRegistration: Scalars['Boolean'];
  durationInterval: SubscriptionInterval;
  durationIntervalCount: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  intervalStartDate: Scalars['DateTime'];
  nextPaymentDate?: Maybe<Scalars['DateTime']>;
  onetimeDiscount: Scalars['Int'];
  participationStartDate: Scalars['DateTime'];
  paymentCycles: Array<Scalars['DateTime']>;
  paymentInterval?: Maybe<PaymentInterval>;
  paymentsRemaining: Scalars['Int'];
  pricingInterval: SubscriptionInterval;
  pricingIntervalCount: Scalars['Int'];
  proRatedDays: Scalars['Int'];
  programPrice: Scalars['Int'];
  proration: Scalars['Int'];
  recurringAmount: Scalars['Int'];
  recurringAmountSurcharge: Scalars['Int'];
  recurringDiscount: Scalars['Int'];
  recurringRegistrationDiscount: Scalars['Int'];
  renewalDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  surcharge?: Maybe<Scalars['Int']>;
  surchargeType?: Maybe<SurchargeType>;
};

export enum SubscriptionInterval {
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export type SubscriptionPayment = Node & {
  __typename?: 'SubscriptionPayment';
  amount: Scalars['Money'];
  createdAt: Scalars['DateTime'];
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  method: Scalars['String'];
  orderLine: OrderLine;
  orderLineId: Scalars['ID'];
  refunds: Array<SubscriptionRefund>;
  state: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SubscriptionPaymentFilterParameter = {
  _and?: InputMaybe<Array<SubscriptionPaymentFilterParameter>>;
  _or?: InputMaybe<Array<SubscriptionPaymentFilterParameter>>;
  amount?: InputMaybe<NumberOperators>;
  assignedCustomerId?: InputMaybe<IdOperators>;
  createdAt?: InputMaybe<DateOperators>;
  customerId?: InputMaybe<IdOperators>;
  errorMessage?: InputMaybe<StringOperators>;
  id?: InputMaybe<IdOperators>;
  membershipStatus?: InputMaybe<StringOperators>;
  method?: InputMaybe<StringOperators>;
  orderLineId?: InputMaybe<IdOperators>;
  state?: InputMaybe<StringOperators>;
  transactionId?: InputMaybe<IdOperators>;
  updatedAt?: InputMaybe<DateOperators>;
};

export type SubscriptionPaymentList = PaginatedList & {
  __typename?: 'SubscriptionPaymentList';
  items: Array<SubscriptionPayment>;
  totalItems: Scalars['Int'];
};

export type SubscriptionPaymentListOptions = {
  filter?: InputMaybe<SubscriptionPaymentFilterParameter>;
  filterOperator?: InputMaybe<LogicalOperator>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<SubscriptionPaymentSortParameter>;
  take?: InputMaybe<Scalars['Int']>;
};

export type SubscriptionPaymentSortParameter = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  errorMessage?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  method?: InputMaybe<SortOrder>;
  orderLineId?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubscriptionRecurringPayment = {
  __typename?: 'SubscriptionRecurringPayment';
  amount: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  interval: SubscriptionInterval;
  intervalCount: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

export type SubscriptionRefund = Node & {
  __typename?: 'SubscriptionRefund';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  method?: Maybe<Scalars['String']>;
  payment: SubscriptionPayment;
  paymentId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  total: Scalars['Money'];
  transactionId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

/** Indicates that an operation succeeded, where we do not want to return any more specific information. */
export type Success = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};

export type Surcharge = Node & {
  __typename?: 'Surcharge';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Money'];
  priceWithTax: Scalars['Money'];
  sku?: Maybe<Scalars['String']>;
  taxLines: Array<TaxLine>;
  taxRate: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type SurchargeConfig = {
  __typename?: 'SurchargeConfig';
  surcharge?: Maybe<Scalars['Float']>;
  surchargeType: SurchargeType;
};

export enum SurchargeType {
  Amount = 'amount',
  Percentage = 'percentage'
}

export type Tag = Node & {
  __typename?: 'Tag';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export type TagList = PaginatedList & {
  __typename?: 'TagList';
  items: Array<Tag>;
  totalItems: Scalars['Int'];
};

export type TaxCategory = Node & {
  __typename?: 'TaxCategory';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TaxLine = {
  __typename?: 'TaxLine';
  description: Scalars['String'];
  taxRate: Scalars['Float'];
};

export type TaxRate = Node & {
  __typename?: 'TaxRate';
  category: TaxCategory;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  customerGroup?: Maybe<CustomerGroup>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
  zone: Zone;
};

export type TaxRateList = PaginatedList & {
  __typename?: 'TaxRateList';
  items: Array<TaxRate>;
  totalItems: Scalars['Int'];
};

export type TextCustomFieldConfig = CustomField & {
  __typename?: 'TextCustomFieldConfig';
  description?: Maybe<Array<LocalizedString>>;
  internal?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Array<LocalizedString>>;
  list: Scalars['Boolean'];
  name: Scalars['String'];
  nullable?: Maybe<Scalars['Boolean']>;
  readonly?: Maybe<Scalars['Boolean']>;
  requiresPermission?: Maybe<Array<Permission>>;
  type: Scalars['String'];
  ui?: Maybe<Scalars['JSON']>;
};

export type TransitionOrderToStateResult = Order | OrderStateTransitionError;

export enum UnavailabilityCode {
  ExistingCartProduct = 'EXISTING_CART_PRODUCT',
  ExistingCartTrial = 'EXISTING_CART_TRIAL',
  ExistingCartVariant = 'EXISTING_CART_VARIANT',
  ExistingOrder = 'EXISTING_ORDER',
  ExistingOrderTrial = 'EXISTING_ORDER_TRIAL',
  ExistsInCart = 'EXISTS_IN_CART',
  OutsideAgeRange = 'OUTSIDE_AGE_RANGE',
  OverlappingSchedule = 'OVERLAPPING_SCHEDULE',
  RequiresTrial = 'REQUIRES_TRIAL',
  TrialCompleted = 'TRIAL_COMPLETED'
}

/**
 * Input used to update an Address.
 *
 * The countryCode must correspond to a `code` property of a Country that has been defined in the
 * Vendure server. The `code` property is typically a 2-character ISO code such as "GB", "US", "DE" etc.
 * If an invalid code is passed, the mutation will fail.
 */
export type UpdateAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Scalars['JSON']>;
  defaultBillingAddress?: InputMaybe<Scalars['Boolean']>;
  defaultShippingAddress?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  streetLine1?: InputMaybe<Scalars['String']>;
  streetLine2?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerCustomFieldsInput = {
  acceptBlueCustomerId?: InputMaybe<Scalars['Int']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  customerRankId?: InputMaybe<Scalars['ID']>;
  guardianId?: InputMaybe<Scalars['ID']>;
  healthProfileResult?: InputMaybe<Scalars['String']>;
  imageId?: InputMaybe<Scalars['ID']>;
  phoneticName?: InputMaybe<Scalars['String']>;
  trialCompleted?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateCustomerEmailAddressResult = IdentifierChangeTokenExpiredError | IdentifierChangeTokenInvalidError | NativeAuthStrategyError | Success;

export type UpdateCustomerInput = {
  customFields?: InputMaybe<UpdateCustomerCustomFieldsInput>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerManagedGroupMemberInput = {
  addresses?: InputMaybe<Array<CustomerManagedGroupAddressInput>>;
  customFields?: InputMaybe<Scalars['JSON']>;
  customerId: Scalars['ID'];
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerPasswordResult = InvalidCredentialsError | NativeAuthStrategyError | PasswordValidationError | Success;

export type UpdateOrderCustomFieldsInput = {
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderInput = {
  customFields?: InputMaybe<UpdateOrderCustomFieldsInput>;
};

export type UpdateOrderItemsResult = InsufficientStockError | NegativeQuantityError | Order | OrderLimitError | OrderModificationError;

export type User = Node & {
  __typename?: 'User';
  authenticationMethods: Array<AuthenticationMethod>;
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  roles: Array<Role>;
  updatedAt: Scalars['DateTime'];
  verified: Scalars['Boolean'];
};

export type ValidationError = ErrorResult & {
  __typename?: 'ValidationError';
  errorCode: ErrorCode;
  errors?: Maybe<Scalars['JSON']>;
  fieldErrors?: Maybe<Scalars['JSON']>;
  message: Scalars['String'];
};

/**
 * Returned if the verification token (used to verify a Customer's email address) is valid, but has
 * expired according to the `verificationTokenDuration` setting in the AuthOptions.
 */
export type VerificationTokenExpiredError = ErrorResult & {
  __typename?: 'VerificationTokenExpiredError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

/**
 * Returned if the verification token (used to verify a Customer's email address) is either
 * invalid or does not match any expected tokens.
 */
export type VerificationTokenInvalidError = ErrorResult & {
  __typename?: 'VerificationTokenInvalidError';
  errorCode: ErrorCode;
  message: Scalars['String'];
};

export type VerifyCustomerAccountResult = CurrentUser | MissingPasswordError | NativeAuthStrategyError | PasswordAlreadySetError | PasswordValidationError | VerificationTokenExpiredError | VerificationTokenInvalidError;

export type Zone = Node & {
  __typename?: 'Zone';
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  members: Array<Region>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ActiveChannelQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveChannelQuery = { __typename?: 'Query', activeChannel: { __typename?: 'Channel', id: string, code: string, currencyCode: CurrencyCode, customFields?: { __typename?: 'ChannelCustomFields', timezone?: string | null, storeURL?: string | null, storePhone?: string | null, storeTitle?: string | null, storeDescription?: string | null, metaTitle?: string | null, metaDescription?: string | null, showVariantDescriptionByDefault?: boolean | null, showSkuForVariants?: boolean | null, favIcon?: { __typename?: 'Asset', id: string, preview: string } | null, storeIcon?: { __typename?: 'Asset', id: string, preview: string } | null, storeHero?: { __typename?: 'Asset', id: string, preview: string } | null, storeLogo?: { __typename?: 'Asset', id: string, preview: string } | null, emailHeader?: { __typename?: 'Asset', id: string, preview: string } | null } | null } };

export type ClassInstanceFragmentFragment = { __typename?: 'RsvClassInstance', id: string, cancelled: boolean, startingAt: any, endingAt: any, date: any, time: string, createdAt: any, updatedAt: any };

export type ClassInstanceParticipantFragmentFragment = { __typename?: 'RsvClassInstanceParticipant', id: string, status?: RsvAttendance | null, createdAt: any, updatedAt: any, participant: { __typename?: 'RsvParticipant', id: string, classStatus: RsvClazzStatus, createdAt: any, updatedAt: any, customer: { __typename?: 'Customer', id: string, firstName: string, lastName: string } } };

export type Rsv_CheckinKioskParticipantMutationVariables = Exact<{
  token: Scalars['String'];
  input: RsvClassIntanceParticipantInput;
}>;


export type Rsv_CheckinKioskParticipantMutation = { __typename?: 'Mutation', rsv_checkinKioskParticipant: { __typename?: 'RsvClassInstanceParticipant', id: string, status?: RsvAttendance | null, createdAt: any, updatedAt: any, participant: { __typename?: 'RsvParticipant', id: string, classStatus: RsvClazzStatus, createdAt: any, updatedAt: any, customer: { __typename?: 'Customer', id: string, firstName: string, lastName: string } } } | { __typename?: 'ValidationError', errorCode: ErrorCode, message: string } };

export type CollectionsQueryVariables = Exact<{
  options?: InputMaybe<CollectionListOptions>;
}>;


export type CollectionsQuery = { __typename?: 'Query', collections: { __typename?: 'CollectionList', items: Array<{ __typename?: 'Collection', id: string, name: string, description: string, slug: string, parent?: { __typename?: 'Collection', name: string } | null, featuredAsset?: { __typename?: 'Asset', id: string, preview: string } | null, customFields?: { __typename?: 'CollectionCustomFields', pricingLowest?: number | null, pricingInterval?: string | null, pricingUseCalculated?: boolean | null } | null }> } };

export type CollectionQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
}>;


export type CollectionQuery = { __typename?: 'Query', collection?: { __typename?: 'Collection', id: string, name: string, description: string, slug: string, featuredAsset?: { __typename?: 'Asset', id: string, preview: string } | null, breadcrumbs: Array<{ __typename?: 'CollectionBreadcrumb', id: string, name: string, slug: string }>, children?: Array<{ __typename?: 'Collection', id: string, name: string, description: string, slug: string, featuredAsset?: { __typename?: 'Asset', id: string, preview: string } | null, customFields?: { __typename?: 'CollectionCustomFields', pricingLowest?: number | null, pricingInterval?: string | null, pricingUseCalculated?: boolean | null } | null }> | null, productVariants: { __typename?: 'ProductVariantList', totalItems: number, items: Array<{ __typename?: 'ProductVariant', price: number, priceWithTax: number, currencyCode: CurrencyCode }> }, customFields?: { __typename?: 'CollectionCustomFields', pricingLowest?: number | null, pricingInterval?: string | null, pricingUseCalculated?: boolean | null } | null } | null };

export type ParticipantFragmentFragment = { __typename?: 'RsvParticipant', id: string, classStatus: RsvClazzStatus, createdAt: any, updatedAt: any, customer: { __typename?: 'Customer', id: string, firstName: string, lastName: string } };

export type PickupCodeFragmentFragment = { __typename?: 'RsvPickupCode', id: string, code: string, customer: { __typename?: 'Customer', id: string, firstName: string, lastName: string } };

export type Rsv_VerifyPickupCodeQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type Rsv_VerifyPickupCodeQuery = { __typename?: 'Query', rsv_verifyPickupCode: { __typename?: 'FailedPickupCodeError' } | { __typename?: 'RsvPickupCode', id: string, code: string, customer: { __typename?: 'Customer', id: string, firstName: string, lastName: string } } };

export const ClassInstanceFragmentFragmentDoc = gql`
    fragment classInstanceFragment on RsvClassInstance {
  id
  cancelled
  startingAt
  endingAt
  date
  time
  createdAt
  updatedAt
}
    `;
export const ParticipantFragmentFragmentDoc = gql`
    fragment participantFragment on RsvParticipant {
  id
  classStatus
  customer {
    id
    firstName
    lastName
  }
  createdAt
  updatedAt
}
    `;
export const ClassInstanceParticipantFragmentFragmentDoc = gql`
    fragment classInstanceParticipantFragment on RsvClassInstanceParticipant {
  id
  status
  participant {
    ...participantFragment
  }
  createdAt
  updatedAt
}
    ${ParticipantFragmentFragmentDoc}`;
export const PickupCodeFragmentFragmentDoc = gql`
    fragment pickupCodeFragment on RsvPickupCode {
  id
  code
  customer {
    id
    firstName
    lastName
  }
}
    `;
export const ActiveChannelDocument = gql`
    query activeChannel {
  activeChannel {
    id
    code
    currencyCode
    customFields {
      timezone
      favIcon {
        id
        preview
      }
      storeIcon {
        id
        preview
      }
      storeHero {
        id
        preview
      }
      storeLogo {
        id
        preview
      }
      emailHeader {
        id
        preview
      }
      storeURL
      storePhone
      storeTitle
      storeDescription
      metaTitle
      metaDescription
      showVariantDescriptionByDefault
      showSkuForVariants
    }
  }
}
    `;
export const Rsv_CheckinKioskParticipantDocument = gql`
    mutation rsv_checkinKioskParticipant($token: String!, $input: RsvClassIntanceParticipantInput!) {
  rsv_checkinKioskParticipant(token: $token, input: $input) {
    ... on RsvClassInstanceParticipant {
      ...classInstanceParticipantFragment
    }
    ... on ErrorResult {
      errorCode
      message
    }
  }
}
    ${ClassInstanceParticipantFragmentFragmentDoc}`;
export const CollectionsDocument = gql`
    query collections($options: CollectionListOptions) {
  collections(options: $options) {
    items {
      id
      name
      description
      slug
      parent {
        name
      }
      featuredAsset {
        id
        preview
      }
      customFields {
        pricingLowest
        pricingInterval
        pricingUseCalculated
      }
    }
  }
}
    `;
export const CollectionDocument = gql`
    query collection($slug: String, $id: ID) {
  collection(slug: $slug, id: $id) {
    id
    name
    description
    slug
    featuredAsset {
      id
      preview
    }
    breadcrumbs {
      id
      name
      slug
    }
    children {
      id
      name
      description
      slug
      featuredAsset {
        id
        preview
      }
      customFields {
        pricingLowest
        pricingInterval
        pricingUseCalculated
      }
    }
    productVariants {
      items {
        price
        priceWithTax
        currencyCode
      }
      totalItems
    }
    customFields {
      pricingLowest
      pricingInterval
      pricingUseCalculated
    }
  }
}
    `;
export const Rsv_VerifyPickupCodeDocument = gql`
    query rsv_verifyPickupCode($token: String!) {
  rsv_verifyPickupCode(token: $token) {
    ...pickupCodeFragment
  }
}
    ${PickupCodeFragmentFragmentDoc}`;
export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    activeChannel(variables?: ActiveChannelQueryVariables, options?: C): Promise<ActiveChannelQuery> {
      return requester<ActiveChannelQuery, ActiveChannelQueryVariables>(ActiveChannelDocument, variables, options) as Promise<ActiveChannelQuery>;
    },
    rsv_checkinKioskParticipant(variables: Rsv_CheckinKioskParticipantMutationVariables, options?: C): Promise<Rsv_CheckinKioskParticipantMutation> {
      return requester<Rsv_CheckinKioskParticipantMutation, Rsv_CheckinKioskParticipantMutationVariables>(Rsv_CheckinKioskParticipantDocument, variables, options) as Promise<Rsv_CheckinKioskParticipantMutation>;
    },
    collections(variables?: CollectionsQueryVariables, options?: C): Promise<CollectionsQuery> {
      return requester<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, variables, options) as Promise<CollectionsQuery>;
    },
    collection(variables?: CollectionQueryVariables, options?: C): Promise<CollectionQuery> {
      return requester<CollectionQuery, CollectionQueryVariables>(CollectionDocument, variables, options) as Promise<CollectionQuery>;
    },
    rsv_verifyPickupCode(variables: Rsv_VerifyPickupCodeQueryVariables, options?: C): Promise<Rsv_VerifyPickupCodeQuery> {
      return requester<Rsv_VerifyPickupCodeQuery, Rsv_VerifyPickupCodeQueryVariables>(Rsv_VerifyPickupCodeDocument, variables, options) as Promise<Rsv_VerifyPickupCodeQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;